import styles from './KPIComp.module.css'
type KPIDATAProps ={
  imgSVg:string;
  count:number;
  des:string;
}

export default function KPIComp (KPIDATA: KPIDATAProps) {
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={KPIDATA.imgSVg} alt='' />
      </div>
      <div className={styles.textContainer}>
        <div className='count text-danger fw-bold '>{KPIDATA.count}</div>
        <div className='des text-disabled'>{KPIDATA.des}</div>
      </div>
    </div>
  )
}
