import moment from "moment";
import InfoElement from "../InfoElement";
import BasicTableComp from "../../../BasicTableComp/BasicTableCom";
import { useState } from "react";
import './DeviceInformation.css'

const titles = [
  { title: "Device Type", attr: "deviceType" },
  { title: "Device Model", attr: "deviceModel" },
  { title: "Serial Number", attr: "serialNumber" },

  //{ title: "Agent Version", attr: "totalRunningDuration" },
];
const columns = [{ header: 'User Name', index: "username" },
{ header: 'Last LogIn', index: "lastlogin" },
{ header: 'Last LogOut', index: "lastlogout" },
{ header: 'Profile Size', index: "profilesize" }]
const rows: Record<string, any>[] = [
  { username: 'Username', lastlogin: 'Now', lastlogout: '24-3-2024', profilesize: '500 GB' },
  { username: 'Username', lastlogin: 'Now', lastlogout: '24-3-2024', profilesize: '500 GB' },
  { username: 'Username', lastlogin: 'Now', lastlogout: '24-3-2024', profilesize: '500 GB' },
  { username: 'Username', lastlogin: 'Now', lastlogout: '24-3-2024', profilesize: '500 GB' },
  { username: 'Username', lastlogin: 'Now', lastlogout: '24-3-2024', profilesize: '500 GB' }
]
export default function DeviceInformation(data: any) {
  const [isShow, setIsShow] = useState(false);
  function isShowHandler() {
    setIsShow(!isShow);
  }
  return (
    <div>
      {data && (
        <ul className="m-2 d-flex flex-column gap-3">
          <InfoElement
            title={"Device Name"}
            description={data.data["deviceName"]}
          />
          <InfoElement
            title={"OS"}
            description={`${data.data["deviceOsName"]}  ${data.data["deviceOsVersion"]}`}
          />

          {titles.map((element: { title: string; attr: string }, index) => (
            <div key={index}>
              <InfoElement
                title={element.title}
                description={data.data[element.attr as keyof typeof data]}
              />
            </div>
          ))}
          <InfoElement
            title={"Last Seen Online"}
            description={moment(
              data.data["lastSeenOnline"],
              "YYYY-MM-DD HH:mm"
            ).toString()}
          />
          <InfoElement
            title={"Total Running Duration"}
            description={
              Math.round(data.data["totalRunningDuration"] / 60 / 60) + " H"
            }
          />
          <InfoElement
            title={"Agent Version"}
            description={data.data["agentVersion"] ?? "-"}
          />
          <InfoElement
            detailsHandler={isShowHandler}
            isShow={isShow}
            title={"Logged in Users"}
            description={data.data["loggedIn"] ?? "-"}
          />
        </ul>

      )}

      <div className={`tableContainer ${isShow ? "slide-down" : "slide-up"}`}><BasicTableComp columns={columns} rows={rows} /></div>
    </div>
  );
}
