import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  deleteDepartment,
  deleteDevice,
  Department,
  getAllDevicesbyDepartmentId,
  getPDFandExcelData,
  getUnassigned
} from '../../services/OrganizationManagementService'
import { ToastFunction, ToastSuccessFunction } from '../../utils/ToastFunction'
type FormData = {
  id?: number
  name: string
  description: string
  computers: any[]
}

// Define the shape of the context
interface OrganizationManegementContextProps {
  handelOpenModal: (mode: 'Edit' | 'Add') => void
  handelCloseModal: () => void
  showModa: 'Edit' | 'Add' | undefined
  handleEdit: (data: Department) => void
  formModalData: FormData
  setFormModalData: Dispatch<SetStateAction<FormData>>
  handelDeleteDep: (id: number) => void
  handelDeleteDevice: (id: number) => void
  ToPdf: () => void
  departmwntsData: [] | Department[]
  setDepartmentData: Dispatch<SetStateAction<Department[]>>
  unAssignedDevices: { value: number; label: string }[] | null
  getDevicesByDepartmentIdState: boolean
  handelDevicesData: (
    data: { id: number; name: string }[]
  ) => { value: number; label: string }[]
  showDeleteModal: boolean,
  setShowDeletemodal: Dispatch<SetStateAction<boolean>>
}

// Create the context
const OrganizationManegementContext = createContext<
  OrganizationManegementContextProps | undefined
>(undefined)

// Custom hook to use the context
export const useOrganizationManegement = () => {
  const context = useContext(OrganizationManegementContext)
  if (!context) {
    throw new Error(
      'useRemoteActions must be used within a RemoteActionsProvider'
    )
  }
  return context
}

// Context provider component
export const OrganizationManegementProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [showModa, setShowmodal] = useState<'Edit' | 'Add' | undefined>(
    undefined
  )
  const [showDeleteModal, setShowDeletemodal] = useState<boolean>(false)
  const [formModalData, setFormModalData] = useState<FormData>({
    id: undefined,
    name: '',
    description: '',
    computers: []
  })

  const [departmwntsData, setDepartmentData] = useState<Department[] | []>([])
  console.log('departmwntsData context ', departmwntsData)

  // unassigned devices general
  const [unAssignedDevices, setUnAssignedDevices] = useState<
    { value: number; label: string }[] | null
  >(null)

  const [getDevicesByDepartmentIdState, setDevicesByDepartmentIdState] =
    useState<boolean>(false)

  useEffect(() => {
    const fetchUnassignedDevices = async () => {
      const devices = await getUnassigned()
      const editedData = handelDevicesData(devices)
      setUnAssignedDevices(editedData)
    }

    fetchUnassignedDevices()
  }, [departmwntsData])

  //HANDEL CLOSE MODAL
  function handelCloseModal () {
    setFormModalData({
      id: undefined,
      name: '',
      description: '',
      computers: []
    })
    setShowmodal(undefined)
  }
  //HANDEL OPEN MODAL
  function handelOpenModal (mode: 'Edit' | 'Add') {
    setShowmodal(mode)
  }
  //HANDEL EDIT CLICK IN ROWS FUNCTION
  const handleEdit = useMemo(() => {
    return async (data: Department) => {
      console.log(data)
      const fetchdepartmentsDevices = async () => {
        setDevicesByDepartmentIdState(true)
        const result = await getAllDevicesbyDepartmentId(data.id!)
        console.log('result==>', result)
        const devices = []
        for (const item of result.computers) {
          const { id: value, name: label } = item
          devices.push({ value, label })
        }
        setFormModalData({
          id: data.id!,
          name: result.name,
          description: result.description,
          computers: devices
        })
        setDevicesByDepartmentIdState(false)
      }
      fetchdepartmentsDevices()
      setShowmodal('Edit')
    }
  }, [])

  // HANDEL DELETE DEPARTMENT FUNCTION
  async function handelDeleteDep (id: number) {
    const res = await deleteDepartment(id)
    if (res === 204) {
      setDepartmentData(prevDepartments => {
        const data = [...prevDepartments]
        const editedDepartment = data.filter(depr => depr.id !== id)
        return editedDepartment
      })
      ToastSuccessFunction('Department is Deleted Successfully...')
    } else {
      ToastFunction('Something went wrong...')
    }
    setShowDeletemodal(false)
  }
  function handelDeleteDevice (id: number) {
    deleteDevice(id)
  }

  async function ToPdf () {
    const { data } = await getPDFandExcelData()
    console.log(data)
  }

  function handelDevicesData (data: { id: number; name: string }[]) {
    return data.map(({ id, name }) => {
      return {
        value: id,
        label: name
      }
    })
  }

  return (
    <OrganizationManegementContext.Provider
      value={{
        handelCloseModal,
        handelOpenModal,
        showModa,
        handleEdit,
        formModalData,
        setFormModalData,
        handelDeleteDep,
        handelDeleteDevice,
        ToPdf,
        departmwntsData,
        setDepartmentData,
        unAssignedDevices,
        getDevicesByDepartmentIdState,
        handelDevicesData,
        showDeleteModal,
        setShowDeletemodal
      }}
    >
      {children}
    </OrganizationManegementContext.Provider>
  )
}
