import { Table } from "../../Table";
import { ConvertSecondsToHHMMSS } from "../../../utils/TimeUtility";
const columns = [
  {
    name: "Desktop Applications",
    attr: "DesktopApplications",
  },
  {
    name: "Duration",
    attr: "applicationUsageDuration",
  },
  {
    name: "Usage",
    attr: "applicationUsagePercentage",
  },
  {
    name: "Total Failures",
    attr: "applicationFailuresCount",
  },
  {
    name: "Percentage Of Failure",
    attr: "applicationFailurePercentage",
  },
];

export default function Applications(data: any) {
  let numberOfDeskTopApps = 0;
  let totalDurations = 0;
  let totalApplicationsFailures = 0;

  let excelData: any = [];
  if (data.data.length > 0) {
    data.data.forEach((app: any) => {
      const excelDataObj = {
        DesktopApplications: app.applicationName,
        applicationUsageDuration: ConvertSecondsToHHMMSS(
          app.applicationUsageDuration
        ),
        usage: Math.round(app.applicationUsagePercentage) + ` %`,
        totalfailures: app.applicationFailuresCount,
        applicationFailurePercentage:
          Math.round(app.applicationFailurePercentage) + "%",
      };
      excelData.push(excelDataObj);
      numberOfDeskTopApps++;
      totalDurations += app.applicationUsageDuration;
      totalApplicationsFailures += app.applicationFailuresCount;
    });
  }
  const rowStrings = {
    key: "applicationName",
    items: data.data,
  };

  const rows = (array: Array<any>) => {
    return array.map((data, index) => (
      <tr key={index}>
        <td>{data.applicationName}</td>

        <td>{ConvertSecondsToHHMMSS(data.applicationUsageDuration)}</td>
        <td>{Math.round(data.applicationUsagePercentage)} %</td>
        <td>{data.applicationFailuresCount}</td>
        <td>{Math.round(data.applicationFailurePercentage)} %</td>
      </tr>
    ));
  };
  return (
    <div>
      {" "}
      <div
        className="d-flex  justify-content-between p-4 my-4 rounded "
        style={{ backgroundColor: "#ECEEF9" }}
      >
        <div className="text-center">
          <div className={"h3 text-dark"}>{numberOfDeskTopApps}</div>

          <div className="h5 text-gray letter-spacing-1">
            No of Desktop Apps
          </div>
        </div>
        <div className="text-center">
          <div className={"h3 text-dark"}>
            {Math.round(totalDurations / 60 / 60)} H
          </div>
          <div className="h5 text-gray letter-spacing-1">Total Duration</div>
        </div>
        <div className="text-center">
          <div className={"h3   text-danger"}>{totalApplicationsFailures}</div>

          <div className={"h5 text-gray letter-spacing-1"}>
            Total Applications Failures
          </div>
        </div>
      </div>
      {data?.data?.length > 0 && (
        <Table
          pageTitle={"Device Applications"}
          // intialPage={intialPage}
          rows={rows}
          rowsData={data.data}
          excelData={excelData}
          columns={columns}
          rowsStrings={rowStrings}
        />
      )}
    </div>
  );
}
