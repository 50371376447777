import { NoData } from "../NoData";
import { Table, width80pxStyle } from "../Table";
import { DetailsIcon } from "../Icons";
import "../../css/InstalledApplication.css";
import { MaliciousTypes } from "../../staticData/securityMonitoring";
import { useState } from "react";
import { MaliciousUrlDetailsModal } from "./MaliciousUrlDetailsModal";
export const MaliciousActivitiesTable = (props: { data: any }) => {
  const { data } = props;
  const [detailModalVisibility, setDetailModalVisibility] = useState(false);
  const [domainUrl, setDomainUrl] = useState<string>("");
  const colwidth =
    window.innerWidth <= 1372 ? { width: "150px" } : { width: "100px" };

  const columns = [
    { name: "Malicious URL", attr: "domainName" }, 

    { name: "Type", attr: "type", style: colwidth },
    { name: "Number Of Devices", attr: "count", style: colwidth },

    { name: "Details", attr: "", style: width80pxStyle },
  ];

  const handelViewDetails = (domain: string) => {
    setDomainUrl(domain);
    setDetailModalVisibility(true);
  };
  const rows = (array: Array<any>) => {
    return array.map((domain, index) => (
      <tr key={index}>
        <td>{domain.url}</td>

        <td>
          <span
            className="rounded-circle text-primary p-2 px-3 font-weight-bold h5"
            style={{ backgroundColor: "#ECEEF9" }}
          >
            {MaliciousTypes[domain.matchedLabel]}
          </span>
        </td>
        <td>{domain.count}</td>

        <td onClick={() => handelViewDetails(domain.url)}>
          <DetailsIcon />
        </td>
      </tr>
    ));
  };

  const rowStrings = {
    key: "url",
    items: data.data.map((domain: any) => [domain.url]),
  };
  const excelData: { domainName: string; type: string; count: number }[] = [];

  data.data.forEach((item: any) => {
    const excelObj = {
      domainName: item.url,
      type: MaliciousTypes[item.matchedLabel],
      count: item.count,
    };

    excelData.push(excelObj);
  });

  return (
    <div className="white-box m-0">
      {data.data.length <= 0 ? (
        <NoData />
      ) : (
        <>
          {detailModalVisibility && (
            <MaliciousUrlDetailsModal
              domain={domainUrl}
              visibility={detailModalVisibility}
              setVisibility={setDetailModalVisibility}
              modalTitle={domainUrl}
            />
          )}

          <Table
            excelData={excelData}
            pageTitle={"Malicious Activities"}
            rows={rows}
            rowsData={data.data}
            columns={columns}
            rowsStrings={rowStrings}
          />
        </>
      )}
      <br />
    </div>
  );
};
