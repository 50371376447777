import styled, { keyframes } from 'styled-components'
import { Spinner } from '@styled-icons/evil/Spinner'

// Define a spin animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

// Create a styled icon with the spin animation
const Spinnerload = styled(Spinner)<{
  iconsize?: string
  defaultcolor?: string
}>`
  width: ${({ iconsize }) => iconsize || '24px'};
  height: ${({ iconsize }) => iconsize || '24px'};
  color: ${({ defaultcolor }) => defaultcolor || '#ffffff'};
  animation: ${spin} 1s linear infinite;
  font-size: 2rem; /* Adjust size as needed */
`

const LoaderSpin = (props: { iconsize?: string; defaultcolor?: string }) => {
  return (
    <Spinnerload iconsize={props.iconsize} defaultcolor={props.defaultcolor} />
  )
}

export default LoaderSpin
