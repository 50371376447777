import styles from './organizationManegement.module.css'
import { Devices } from '@styled-icons/material-rounded/Devices'
import { Add } from '@styled-icons/fluentui-system-filled/Add'
import styled from 'styled-components'
import Listing from '../../Listing/ListingBody/Listing'
import { useListing } from '../../Listing/ListingContext'
import { useEffect, useMemo, useState } from 'react'
import {
  Department,
  getAllDevicesbyDepartmentId
} from '../../../services/OrganizationManagementService'
import OrganizationDepartmentModal from '../OrganizationManagementModal/OrganizationDepartmentModal'
import { useOrganizationManegement } from '../OrganizationManegemnetContext'
import { DeleteIcon, EditIcon, RightArrowIcon } from '../../Icons'
import { DeleteModal } from '../OrganizationManagementDeleteModal'
const StyledDevices = styled(Devices)`
  color: #36429e;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`
const StyledAdd = styled(Add)`
  color: #fffff;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

interface Data {
  data: Department[]
  unAssignedDevices: number
}

const OrganizationManagementBody = ({ data }: Data) => {
  const [selecteddep, setSelectedDep] = useState<number | undefined>(undefined)
  let { setColumns, setRows, state, setListedData, showMoreHandler, query } =
    useListing()
  const {
    showModa,
    handelOpenModal,
    handleEdit,
    handelDeleteDep,
    setDepartmentData,
    departmwntsData,
    unAssignedDevices,
    setShowDeletemodal,
    showDeleteModal
  } = useOrganizationManegement()

  const columns = useMemo(
    () => [
      { indexName: 'name', HeaderName: 'DepartmentName' },
      { indexName: 'description', HeaderName: 'Description' },
      { indexName: 'numOfComputers', HeaderName: 'No of Devices' },
      { indexName: '', HeaderName: 'Edit' },
      { indexName: '', HeaderName: 'Delete' },
      { indexName: '', HeaderName: '' }
    ],
    []
  )
  const rows = useMemo(() => {
    return departmwntsData?.map((row, index) => (
      <tr key={row.id} data-id={row.id} className={styles['mainRow']}>
        {columns?.map(col =>
          col.indexName ? (
            <td key={`${row.id}-${col.indexName}`}>
              <div>{row[col.indexName as keyof Department] ?? 'No Data'}</div>
            </td>
          ) : null
        )}
        <td>
          <div
            onClick={() =>
              state !== undefined ? showMoreHandler(index + 1, row.id) : null
            }
          >
            <EditIcon handleEdit={() => handleEdit(row)} />
          </div>
        </td>
        <td>
          <div>
            <DeleteIcon
              defaultcolor='red'
              handleDelete={() => {
                setSelectedDep(row.id)
                setShowDeletemodal(true)
              }}
            />
          </div>
        </td>
        <td onClick={() => showMoreHandler(index + 1, row.id)}>
          <div>
            <RightArrowIcon
              state={state?.index === index + 1}
            />
          </div>
        </td>
      </tr>
    ))
  }, [
    departmwntsData,
    columns,
    state,
    showMoreHandler,
    handleEdit,
    setShowDeletemodal
  ])

  useEffect(() => {
    setDepartmentData(data)
  }, [data, setDepartmentData])

  useEffect(() => {
    if (query.trim()) {
      // Trim whitespace to avoid unnecessary filtering
      console.log('Filtering data based on query:', query)
      const filteredData = data.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase())
      )
      console.log(' Data:', data)
      console.log('Filtered Data:', filteredData)
      setDepartmentData(filteredData)
    } else {
      // Reset to original data when query is empty
      setDepartmentData(data)
    }
  }, [data, query, setDepartmentData])

  useEffect(() => {
    setColumns(columns)
    setRows(rows)
  }, [state, departmwntsData, setColumns, setRows, columns, rows])

  // CALLING LISTING DATA
  useEffect(() => {
    async function getdevices () {
      if (state !== undefined) {
        const listedData = await getAllDevicesbyDepartmentId(state.id)
        setListedData({
          rowId: state.id,
          data: listedData
        })
      }
    }
    getdevices()
  }, [state, setListedData])

  console.log('departmwntsData ==> ', departmwntsData)

  return (
    <div>
      <div className={styles['organizationHeader']}>
        <div className={styles['unassignedDevices']}>
          <span className={styles['icon']}>
            <StyledDevices />
          </span>
          <div className={styles['data']}>
            <p className='count m-0'>{unAssignedDevices?.length}</p>
            <span className='text'>Unassign Devices</span>
          </div>
        </div>

        <button
          className={styles['addNew']}
          onClick={() => handelOpenModal('Add')}
        >
          <StyledAdd /> New Department
        </button>
      </div>

      <div className={styles['organizationbody']}>
        <Listing length={departmwntsData.length} />
      </div>

      {showModa && <OrganizationDepartmentModal />}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          deleteButton={
            <button
              className='bg-danger text-white px-4 py-2'
              onClick={() =>
                selecteddep !== undefined && handelDeleteDep(selecteddep)
              }
            >
              Delete
            </button>
          }
          handleCloseDeleteModal={() => {
            setSelectedDep(undefined)
            setShowDeletemodal(false)
          }}
          currentAppName={null}
        />
      )}
    </div>
  )
}
export default OrganizationManagementBody
