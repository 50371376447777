import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { DateTime } from 'luxon'
import { Group, user } from '../client/AuthedClient'
import { useAuthedClient } from '../context/AuthedClientContext'
import { DateRangePicker } from 'rsuite'
import { useItemFilterContext } from '../context/ItemFilterContext'
import enUS from 'rsuite/locales/en_US'
import styled from 'styled-components'
import { User } from '@styled-icons/boxicons-regular/User'
import { People } from '@styled-icons/bootstrap/People'
import '../css/PageHeaderStyles.css'
const UserIcon = styled(User)`
  font-size: 0.875rem;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  color: #b9b9b9;
`
const PeopleIcon = styled(People)`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  color: #b9b9b9;
`
const { afterToday }: any = DateRangePicker
const options: Array<{
  name: string
  startDate: DateTime
  endDate: DateTime
}> = [
    {
      name: 'Today',
      startDate: DateTime.now(),
      endDate: DateTime.now()
    },
    {
      name: 'Yesterday',
      startDate: DateTime.now().minus({ day: 1 }),
      endDate: DateTime.now().minus({ day: 1 })
    },
    {
      name: 'Last 7 Days',
      startDate: DateTime.now().minus({ day: 6 }),
      endDate: DateTime.now()
    },
    {
      name: 'Last 30 Days',
      startDate: DateTime.now().minus({ day: 29 }),
      endDate: DateTime.now()
    },
    {
      name: 'This month',
      startDate: DateTime.now().set({ day: 1 }),
      endDate: DateTime.now()
    },
    {
      name: 'Last month',
      startDate: DateTime.now().minus({ month: 1 }).set({ day: 1 }),
      endDate: DateTime.now().set({ day: 1 }).minus({ day: 1 })
    }
  ]
const dateRangePickerRanges = options.map(option => ({
  label: option.name,
  value: [option.startDate.toJSDate(), option.endDate.toJSDate()],
  closeOverlay: true
}))
const initialOptionIndex = 3
export const initialStartDate = options[initialOptionIndex].startDate
export const initialEndDate = options[initialOptionIndex].endDate
export const current = 0
export const selctedUser = undefined
export const PageHeader = ({
  disabled,
  onFilterChanged,
  isDateFilter,
  isUserFilter,
  isGroupFilter
}: {
  title: {
    mainTitle: string
    subTitle: string
  }
  disabled: boolean
  onFilterChanged: (
    startDate: DateTime,
    endDate: DateTime,
    groupId: number | undefined,
    userId: number | undefined
  ) => void
  isDateFilter?: boolean
  isUserFilter: boolean
  isGroupFilter?: boolean
}) => {
  const authedClient = useAuthedClient()
  const { setfilterByItem } = useItemFilterContext()
  const [groups, setGroups] = useState<Array<Group>>([])
  const [currentGroup, setCurrentGroup] = useState<number | undefined>(undefined)
  const [users, setUsers] = useState<Array<user>>([])
  const [currentUser, setCurrentUser] = useState<number | undefined>(undefined)
  const [dropdownSearchTerm, setDropDownSearchTerm] = useState('')
  const [dropdownGroupSearchTerm, setDropDownGroupSearchTerm] = useState('')
  const [startEndDate, setStartEndDate] = useState<[Date, Date]>([
    initialStartDate.toJSDate(),
    initialEndDate.toJSDate()
  ])
  const userInputEl = useRef<HTMLInputElement | null>(null)
  const groupInputEl = useRef<HTMLInputElement | null>(null);

  console.log('users==> ', users);
  console.log('departments ==> ', groups);
  ;



  useEffect(() => {
    authedClient.getGroups().then(res =>
      setGroups([
        // {
        //   recordId: 1,
        //   groupName: "Undefined department",
        //   creationDate: "string",
        //   isDeleted: false,
        // },
        ...res
      ])
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (currentGroup == null) {
      authedClient.getUsers().then(res => setUsers(res))
    } else {
      authedClient.getUsers(currentGroup).then(res => setUsers(res))
    }
    setCurrentUser(undefined)
  }, [currentGroup, authedClient])
  function focus(
    nextShow: boolean,
    el: MutableRefObject<HTMLInputElement | null>
  ) {
    if (nextShow) {
      setTimeout(() => el.current?.focus())
    }
  }
  function getGroupName() {
    let groupName = ''
    if (currentGroup === undefined) {
      groupName = 'All Departments'
    } else if (currentGroup === 0) {
      groupName = 'Undefined Department'
    } else {
      const group = groups.filter(el => {
        return el.id === currentGroup
      })

      groupName = group[0].name
    }
    return groupName
  }
  function getUserName() {
    let UserName = ''
    if (currentUser === undefined && currentGroup === undefined) {
      UserName = 'All Devices'
    } else if (currentGroup !== undefined && currentUser === undefined) {
      UserName = "Department Devices"
    }
    else {
      const user = users.filter(el => {
        return el.computerId === currentUser
      })
      UserName = user[0].computerName
    }
    return UserName
  }
  function handleGroupDropdownItemSelected(eventKey: string | null) {
    if (eventKey !== null) {
      if (eventKey === 'all') {
        setCurrentGroup(undefined);

        onFilterChanged(
          DateTime.fromJSDate(startEndDate[0]),
          DateTime.fromJSDate(startEndDate[1]),
          undefined,
          currentUser
        )
      } else if (eventKey === 'undefind') {
        setCurrentGroup(0)
        onFilterChanged(
          DateTime.fromJSDate(startEndDate[0]),
          DateTime.fromJSDate(startEndDate[1]),
          0,
          currentUser
        )
      } else {
        setCurrentGroup(Number(eventKey))
        onFilterChanged(
          DateTime.fromJSDate(startEndDate[0]),
          DateTime.fromJSDate(startEndDate[1]),
          Number(eventKey),
          currentUser
        )
      }
    }
  }
  function handleUserDropdownItemSelected(eventKey: string | null) {
    if (eventKey !== null) {
      if (eventKey === 'all') {
        setCurrentUser(undefined)
        setfilterByItem(false)
        onFilterChanged(
          DateTime.fromJSDate(startEndDate[0]),
          DateTime.fromJSDate(startEndDate[1]),
          currentGroup,
          undefined
        )
      } else {
        setCurrentUser(Number(eventKey))
        setfilterByItem(true)
        onFilterChanged(
          DateTime.fromJSDate(startEndDate[0]),
          DateTime.fromJSDate(startEndDate[1]),
          currentGroup,
          Number(eventKey)
        )
      }
    }
  }
  const handleClearDropDownState = () => {
    setDropDownSearchTerm('')
    setDropDownGroupSearchTerm('')
  }

  if (isDateFilter || isUserFilter || isGroupFilter) {
    return (
      <div className='page-title justify-content-sm-center flex-sm-column d-md-flex flex-md-row justify-content-md-between align-items-center'>
        <div className='topFilter row g-2 mb-2 mb-md-0'>
          {isDateFilter ? (
            <div className='col-auto rsuite-custom-class filterBody'>
              <div className='topFilter row g-2'>
                <DateRangePicker
                  style={disabled ? { pointerEvents: 'none' } : {}}
                  cleanable={false}
                  format='MMM dd, yyyy'
                  locale={enUS}
                  value={startEndDate}
                  disabledDate={afterToday()}
                  ranges={dateRangePickerRanges as any}
                  onChange={(value: any) => {
                    if (value !== null) {
                      setStartEndDate(value)
                      onFilterChanged(
                        DateTime.fromJSDate(value[0]),
                        DateTime.fromJSDate(value[1]),
                        currentGroup,
                        currentUser
                      )
                    }
                  }}
                />
                <i className='icon-down-open-mini' />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className='topFilter row g-2'>
          {isUserFilter ? (
            <div className='col-auto filterBody'>
              <Dropdown
                onSelect={(eventKey: any) =>
                  handleUserDropdownItemSelected(eventKey)
                }
                onToggle={(nextShow: any) => {
                  focus(nextShow, userInputEl)
                  handleClearDropDownState()
                }}
              >
                <Dropdown.Toggle
                  variant='light'
                  style={disabled ? { pointerEvents: 'none' } : {}}
                >
                  <UserIcon className='user-filter-icon' /> {getUserName()}
                </Dropdown.Toggle>
                <Dropdown.Menu className='user-filter-dropdown-menu'>
                  <div className='dropdown-search-input table-search'>
                    <input
                      ref={userInputEl}
                      type='search'
                      value={dropdownSearchTerm}
                      className='form-control form-control-sm'
                      placeholder=''
                      aria-controls='dtBasicExample'
                      onChange={e => setDropDownSearchTerm(e.target.value)}
                    />
                  </div>
                  <Dropdown.Item
                    eventKey={'all'}
                    onClick={handleClearDropDownState}
                    className={`${currentUser === undefined
                      ? 'page-header-dropdown page-header-dropdown-light-text-active'
                      : 'page-header-dropdown'
                      }`}
                  >
                    {
                      currentGroup !== undefined ? 'Department Devices' : "All Devices"
                    }
                  </Dropdown.Item>
                  {users
                    .filter(user => {
                      if (dropdownSearchTerm === '') {
                        return user
                      } else if (
                        user.computerName
                          .toLowerCase()
                          .includes(dropdownSearchTerm.toLowerCase())
                      ) {
                        return user
                      }
                      return 0
                    })
                    .map((option: user, index: number) => (
                      <Dropdown.Item
                        onClick={handleClearDropDownState}
                        href='#'
                        key={index}
                        eventKey={option.computerId}
                        // active={index === selectedOptionIndex}
                        className={`${option.computerId === currentUser
                          ? 'page-header-dropdown page-header-dropdown-light-text-active'
                          : 'page-header-dropdown'
                          }`}
                      >
                        {option.computerName}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <></>
          )}
          {isGroupFilter ? (
            <div className='col-auto filterBody'>
              <Dropdown
                onSelect={(eventKey: any) =>
                  handleGroupDropdownItemSelected(eventKey)
                }
                onToggle={(nextShow: any) => {
                  focus(nextShow, userInputEl)
                  handleClearDropDownState()
                }}
              >
                <Dropdown.Toggle
                  variant='light'
                  style={disabled ? { pointerEvents: 'none' } : {}}
                >
                  <PeopleIcon className='group-filter-icon' /> {getGroupName()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className='dropdown-search-input table-search'>
                    <input
                      ref={groupInputEl}
                      type='search'
                      className='form-control form-control-sm'
                      placeholder=''
                      aria-controls='dtBasicExample'
                      value={dropdownGroupSearchTerm}
                      onChange={e => setDropDownGroupSearchTerm(e.target.value)}
                    />
                  </div>
                  <Dropdown.Item
                    eventKey={'all'}
                    onClick={handleClearDropDownState}
                    className={`${currentGroup === null
                      ? 'page-header-dropdown page-header-dropdown-light-text-active'
                      : 'page-header-dropdown'
                      }`}
                  >
                    All Departments
                  </Dropdown.Item>
                  {groups
                    .filter(group => {
                      if (dropdownGroupSearchTerm === '') {
                        return group
                      } else if (
                        group.name
                          .toLowerCase()
                          .includes(dropdownGroupSearchTerm.toLowerCase())
                      ) {
                        return group
                      }
                      return 0
                    })
                    .map((option: Group, index: number) => (
                      <Dropdown.Item
                        onClick={handleClearDropDownState}
                        href='#'
                        key={index}
                        eventKey={
                          option.name === 'Undefined'
                            ? 'all'
                            : option.id
                        }
                        className={`${currentGroup && option.id === currentGroup
                          ? 'page-header-dropdown page-header-dropdown-light-text-active'
                          : 'page-header-dropdown'
                          }`}
                      >
                        {option.name}
                      </Dropdown.Item>
                    ))}
                  <Dropdown.Item
                    eventKey={'undefind'}
                    key={'undefind'}
                    onClick={handleClearDropDownState}
                    className={`${currentGroup === 0
                      ? 'page-header-dropdown page-header-dropdown-light-text-active'
                      : 'page-header-dropdown'
                      }`}
                  >
                    Undefined Department
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  } else return <></>
}
