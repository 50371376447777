import styled from "styled-components";
import { ChevronThinDown } from "@styled-icons/entypo/ChevronThinDown";

// Make the styling dynamic by accessing props
const ChevronThinDownicon = styled(ChevronThinDown)<{
  iconsize?: string;
  defaultcolor?: string;
  hovercolor?: string;
  focuscolor?: string;
}>`
  width: ${({ iconsize }) => iconsize || "24px"};
  height: ${({ iconsize }) => iconsize || "24px"};
  color: ${({ defaultcolor }) => defaultcolor || "#6a6a6a"};
  cursor: pointer;

  &:hover {
    color: ${({ hovercolor }) => hovercolor || "#36429e"};
  }

  &:focus,
  &:active {
    color: ${({ focuscolor }) => focuscolor || "#4453c5"};
  }
`;

function ChevronThinDownIcon(props: {
  handleEdit?: () => void;
  iconsize?: string;
  defaultcolor?: string;
  hovercolor?: string;
  focuscolor?: string;
}) {
  return (
    <ChevronThinDownicon
      onClick={props.handleEdit}
      iconsize={props.iconsize}
      defaultcolor={props.defaultcolor}
      hovercolor={props.hovercolor}
      focuscolor={props.focuscolor}
    />
  );
}

export default ChevronThinDownIcon;
