import { useCallback , useMemo} from 'react'
import { ExcelIcon, PdfIcon, SearchIcon } from '../../Icons'
import { useListing } from '../ListingContext'
import styles from './ListingHeader.module.css'
import _ from 'lodash'
import styled from 'styled-components'
import { Delete } from '@styled-icons/typicons/Delete'
const RemoveIcon = styled(Delete)<{
  inputSearchValue: string
  searchValue: string
}>`
  width: 30px;
  height: 30px;
  visibility: ${props =>
    props.inputSearchValue.length > 0 || props.searchValue.length > 0
      ? 'visible'
      : 'hidden'};
  color: #d3d3d3;
`

export default function ListingHeader () {
  const {
    setFilterDepartment,
    filterDepartment,
    PDFHandler,
    downloadExcel,
    query,
    setQuery
  } = useListing()

  //  APPLY DEBOUNCING
  const handleSearch = useCallback((value: string) => {
    setFilterDepartment(value);
  }, [ setFilterDepartment]);

  const debouncedSearch = useMemo(() => {
    return _.debounce(handleSearch, 500);
  }, [handleSearch]);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    debouncedSearch(e.target.value)
  }
  
  return (
    <div className={styles['listingHeader-container']}>
      <div className={styles['inputcontaier']}>
        <span>
          <SearchIcon />
        </span>
        <input
          type='text'
          placeholder='Search...'
          onChange={onChange}
          value={query}
        />
        <RemoveIcon inputSearchValue={filterDepartment} searchValue={query} />
      </div>
      <div className='exportContainer'>
        <button className='pdf' onClick={PDFHandler}>
          <PdfIcon />
          PDF
        </button>
        <button onClick={downloadExcel}>
          <ExcelIcon /> Excel
        </button>
      </div>
    </div>
  )
}
