import http from './httpService'





// GET APPLICATION NAMES VS NUMBER OF FAILURES
export async function getApplicationsNameAndNumOfFailures(from, to, id) {
  const startDate = from.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
  const endDate =to.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
  
  try {
    const queryParams = [
      id ? `computerId=${id}` : '',
      from && to ? `startDate=${startDate}&endDate=${endDate}` : ''
    ]
      .filter(Boolean) // Remove empty values
      .join('&'); // Join with & if multiple parameters exist

    const url = `/Dashboard/top-applications-failures${queryParams ? `?${queryParams}` : ''}`;

    const res = await http.get(url);
    return res.data;
  } catch (error) {
    console.error('Error fetching applications data:', error);
    throw error; // Re-throw to handle it where needed
  }
}
