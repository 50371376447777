import { CSVLink } from "react-csv";
import styled from "styled-components";
import { Search } from "@styled-icons/bootstrap/Search";
import { Delete } from "@styled-icons/typicons/Delete";
import "./TableSearchStyle.css";
import { ExcelIcon, PdfIcon } from "../Icons";
import { RefObject, useRef, useState } from "react";
import Link from "react-csv/components/Link";
const SearchIcon = styled(Search)``;
const RemoveIcon = styled(Delete)<{
  inputSearchValue: string;
  tableType: string | undefined;
  searchValue: string;
}>`
  width: 30px;
  height: 30px;
  display: ${(props) =>
    props.inputSearchValue.length > 0 ||
    (props.tableType === "paginatedTable" && props.searchValue.length > 0)
      ? "block"
      : "none"};
  color: #d3d3d3;
`;

// Custom debounce function
const debounce = (func: (value: string) => void, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value; // Access the value directly
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(value); // Call the provided function with the input value
    }, delay);
  };
};
export const TableSearch = ({
  pageTitle,
  setTableRowsPerPage,
  setSearchTerm,
  excelContent,
  setCurrentPage,
  currentPage,
  tableRowsPerPage,
  toPdf,
  toExcel,
  searchValue,
  compParent,
}: {
  pageTitle: string;
  arrayLength: number;
  currentPage: number;
  tableRowsPerPage: number;
  setTableRowsPerPage: (tableRowsPerPage: number) => void;
  setSearchTerm: (searchTerm: string) => void;
  setCurrentPage: (currentPage: number) => void;
  toPdf: () => void;
  toExcel?: () => Promise<void>;
  excelContent: any;
  searchValue?: string;
  compParent?: string;
}) => {
  // to prevent lag because of debouncinf fun
  const [inputSearchValue, setInputSearchValue] = useState(""); // Immediate input state
  const handlePaginationItemsNumber = (e: React.ChangeEvent) => {
    if (currentPage > 1 && tableRowsPerPage >= 10) {
      setCurrentPage(1);
    }
    setTableRowsPerPage(Number((e.target as HTMLInputElement).value));
  };
  const csvLink = useRef<
    string | RefObject<Link> | ((instance: Link | null) => void)
  >();

  let placeholder: string;
  switch (pageTitle) {
    case "User Management":
      placeholder = "Username";
      break;
    case "Remote Actions History":
      placeholder = "Action Name";
      break;
    case "Agent Availability Date":
    case "Devices Compliance Details":
      placeholder = "Date";
      break;
    case "Application License Detail":
      placeholder = "status";
      break;
    case "License Expiration Monitoring":
      placeholder = "Number of License";
      break;
    case "Visited Sites":
    case "User Activity":
      placeholder = "Website Name";
      break;
    case "Assign Role":
      placeholder = "Username";
      break;
    case "Domain Management":
      placeholder = "Domain Name";
      break;
    case "Malicious Activities":
      placeholder = "Malicious URL";
      break;
    case "Applications Performance":
      placeholder = "Application Name";
      break;
    case "Remote Actions":
      placeholder = "Action Name";
      break;
    case "Applications Compliance":
    case "Application Usage":
    case "License Monitoring":
    case "Application Usage Summary":
    case "Disk Performance":
    case "Memory Performance":
    case "Web Availability":
    case "Cpu Performance":
    case "Application License":
    case "Allowed Applications":
    case "Installed Applications":
    case "Web Applications":
    case "Configure Alerts":
      placeholder = "Application Name";
      break;
    case "Applications Availability":
    case "Non Compliant Applications":
    case "Compliant Applications":
    case "Device Applications":
      placeholder = "Application Name";
      break;
    default:
      placeholder = "Device Name";
  }

  // onchange fun
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputSearchValue(value); // Update the immediate input value
    debouncedHandleChange(e); // Trigger the debounced change
  };
  // Using the debounce function
  const debouncedHandleChange = debounce((value: string) => {
    setSearchTerm(value); // Update the state with the debounced value
  }, 800); // Adjust the debounce delay as needed

  // clearance fun
  const removeSearchHandler = () => {
    setInputSearchValue(""); // Clear the input value which appear in the input
    setSearchTerm(""); // Clear the search term which will send to the parent
  };
  

  return (
    <div className="table-search-row">
      <div className="tabel-search-left-side">
        <div className="table-show-entries" id="dtBasicExample_length">
          <label>Show</label>
          <select
            name="dtBasicExample_length"
            aria-controls="dtBasicExample"
            className="custom-select custom-select-sm form-control form-control-sm"
            onChange={handlePaginationItemsNumber}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div className="table-search">
          <SearchIcon className="SearchIconstyle" />
        
          {compParent === "paginatedTable" ? (
            <input
              autoComplete="new-password"
              className="form-control form-control-sm"
              placeholder={placeholder}
              aria-controls="dtBasicExample"
              onChange={handleChange}
              defaultValue={searchValue}
            />
          ) : (
            <input
              autoComplete="new-password"
              className="form-control form-control-sm"
              placeholder={placeholder}
              aria-controls="dtBasicExample"
              onChange={handleChange}
              value={inputSearchValue}
              defaultValue={searchValue}
            />
          )}
          <button onClick={removeSearchHandler}>
            <RemoveIcon
              inputSearchValue={inputSearchValue}
              tableType={compParent}
              searchValue={searchValue!}
            />
          </button> 
        </div>
      </div>
      <div className="download-btns-container">
        <div className="download-btn-pdf" onClick={toPdf}>
          <button>
            <PdfIcon /> PDF
          </button>
        </div>
        <div className="download-btn-csv">
          <button
            onClick={async () => {
              if (toExcel) await toExcel();
              //@ts-ignore
              csvLink.current?.link.click();
            }}
          >
            <ExcelIcon />
            Excel
          </button>

          <CSVLink
            //@ts-ignore
            ref={csvLink}
            asyncOnClick={true}
            filename={
              pageTitle !== undefined || pageTitle === " "
                ? `${pageTitle}-Report.csv`
                : "Data-Report.csv"
            }
            data={excelContent}
          ></CSVLink>
        </div>
      </div>
    </div>
  );
};
