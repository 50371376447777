import Button from "../Button/Button";
import { DetailsIcon } from "../Icons";
import { NoData } from "../NoData";
import { Table } from "../Table";

export type AppData = {
  applicationId: number;
  applicationName: string;
  applicationVersion: string[];
  details: Array<Object>;
  numberOfActiveLicenses: number;
  numberOfInActiveLicenses: number;
};

export const ApplicationLicenseTable = (props: {
  handleShowDetails: (process: any) => void;
  processes: Array<AppData>;
  handleAddNew: () => void;
}) => {
  const { handleShowDetails, processes, handleAddNew } = props;
  const columns = [
    {
      name: "Application Name",
      attr: "applicationName",
      style: { width: "180px" },
    },
    {
      name: "Active Licenses",
      attr: "numberOfActiveLicenses",
      style: { width: "138px" },
    },
    {
      name: "Inactive Licenses",
      attr: "numberOfInActiveLicenses",
      style: { width: "148px" },
    },
    { name: "Details", attr: "", style: { width: "85px" } },
  ];

  const rows = (array: Array<any>) => {
    return array.map((process, index) => (
      <tr key={index}>
        <td>{process.applicationName}</td>
        <td>{process.numberOfActiveLicenses}</td>
        <td>{process.numberOfInActiveLicenses}</td>
        <td>
          <DetailsIcon handleOpenModal={() => handleShowDetails(process)} />
        </td>
      </tr>
    ));
  };
  const rowStrings = {
    key: "applicationName",
    items: processes.map((process) => [process.applicationName]),
  };
  const tableData: { [key: string]: Object } = {};
  processes.forEach((app) => {
    const details = app.details;
    details.forEach(
      //@ts-ignore
      (detail) => (detail["applicationVersion"] = app.applicationVersion)
    );
    if (tableData[app.applicationName]) {
      const appObject: AppData | any = tableData[app.applicationName];
      appObject.applicationVersion = [
        ...appObject.applicationVersion,
        app.applicationVersion,
      ];
      appObject.details = [...appObject.details, ...app.details];
      appObject.numberOfActiveLicenses += app.numberOfActiveLicenses;
      appObject.numberOfInActiveLicenses += app.numberOfInActiveLicenses;
    }
    if (!tableData[app.applicationName]) {

      let obj = {
        applicationId: app.applicationId,
        applicationName: app.applicationName,
        applicationVersion: [app.applicationVersion],
        details: app.details,
        numberOfActiveLicenses: app.numberOfActiveLicenses,
        numberOfInActiveLicenses: app.numberOfInActiveLicenses,
      };
      tableData[app.applicationName] = obj;
    }

  });
  const tableDataToArray = Object.values(tableData);

  const excelData = tableDataToArray.map((el: any) => {
    let excelObj = {
      applicationName: el.applicationName,
      numberOfActiveLicenses: el.numberOfActiveLicenses,
      numberOfInActiveLicenses: el.numberOfInActiveLicenses,
    };

    return excelObj;
  });
  return (
    <div className="white-box m-0">
      <div style={{ textAlign: "right", marginBottom: "5px" }}>
        <Button title="Add Application License " handleClick={handleAddNew} />
      </div>
      {processes.length <= 0 ? (
        <NoData />
      ) : (
        <Table
          excelData={excelData}
          pageTitle={"Application License"}
          rows={rows}
          rowsData={tableDataToArray}
          columns={columns}
          rowsStrings={rowStrings}
        />
      )}
    </div>
  );
};
