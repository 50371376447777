import { RightArrowIcon } from "../../Icons";
import "../InfoElement.css";

type Props = {
  title: string;
  description: unknown;
  details?: string;
  detailsHandler?: () => void;
  isShow?: boolean;
};
export default function InfoElement({
  title,
  description,
  details = "",
  detailsHandler,
  isShow,
}: Props) {
  return (
    <div className="d-flex infoContainer">
      <div className="infoTitle p-4 d-flex flex-column justify-content-center">
        {title}
      </div>
      <div className="p-4 infoBody">
        {description}
        <span style={{ color: "#34B53A", paddingLeft: "1rem" }}>{details}</span>
        {title === "Logged in Users" ? (
          <span onClick={detailsHandler} className="detail">
            See Details <RightArrowIcon state={isShow} />
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
