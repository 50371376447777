import ReactApexChart from "react-apexcharts";
import colorArray from "../../common/Colors";
import styles from "./DonutChart.module.css";

type DonutChartProps = {
  data: {
    yAxisData: number[];
    labels: string[];
  };
  title: string;
};

export default function DonutChart(DonutChartData: DonutChartProps) {
  console.log("DonutChartData", DonutChartData);

  return (
    <div className={styles.ChartConatiner}>
      <div className="w-100">
        <h3>{DonutChartData.title}</h3>
        {DonutChartData.data.yAxisData.length !== 0 ? (
          <div className={styles.donutChart}>
            <ReactApexChart
              width={"95%"}
              height={400}
              series={DonutChartData.data.yAxisData}
              type="donut"
              options={{
                colors: colorArray,
                legend: {
                  show: true,
                  position: "top",
                  offsetY: -10,
                  itemMargin: {
                    horizontal: 10,
                    vertical: 20,
                  },
                },
                dataLabels: {
                  enabled: false, // Disables data labels on the chart
                },
                tooltip: {
                  enabled: true, // Enable tooltip
                  y: {
                    formatter: (value) => {
                      return `${value}%`;
                    },
                  },
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: "60%", // Increase the size to make the donut thicker
                    },
                  },
                },
                labels: DonutChartData.data.labels,
              }}
            />
          </div>
        ) : (
          <div className='h-100 d-flex justify-content-center align-items-center'>
            <p 
            className="mb-5">No Data Available</p>
          </div>
        )}
      </div>
    </div>
  );
}
