import { Link } from "react-router-dom";
import { Table } from "../Table";
import moment from "moment";
import { roundNumber } from "../../utils/Mathimatics";
import { ConvertSecondsToHHMMSS } from "../../utils/TimeUtility";

interface DeviceData {
  computerId: string;
  computerName: string;
  computerOsName: string;
  computerOsVersion: string;
  computerOsArchitecture: string;
  deviceType: string;
  deviceModel: string;
  installedRam: number;
  diskTotalSpace: string;
  diskFreeSpace: string;
  lastSeen: string;
  totalDuration: number;
  totalFailureCount: number;
}

interface DevicesTableProps {
  data: DeviceData[];
}

export default function DevicesTable({ data }: DevicesTableProps) {
  const columns = [
    { name: "Device Name", attr: "computerName" },
    { name: "Operating System", attr: "computerOsName" },
    { name: "Device Type", attr: "deviceType" },
    { name: "Device Model", attr: "deviceModel" },
    { name: "RAM", attr: "installedRam" },
    { name: "Disk Usage", attr: "diskTotalSpace" },
    { name: "Last Seen Online", attr: "lastSeen" },
    { name: "Duration", attr: "totalDuration" },
    { name: "Total Failures", attr: "totalFailureCount" },
  ];

  const getRoute = (basePath: string, device: DeviceData) =>
    `${basePath}/${device.computerId}/${device.computerName}`;

  const rows = (devices: DeviceData[]) =>
    devices.map((device, index) => {
      const deviceInfoRoute = getRoute(
        "/device-view/Device-Information",
        device
      );
      const systemResourcesRoute = getRoute(
        "/device-view/System-Resources",
        device
      );
      const applicationsRoute = getRoute("/device-view/Applications", device);

      return (
        <tr key={index}>
          <td className="center-text">
            <Link to={deviceInfoRoute}>{device.computerName}</Link>
          </td>
          <td className="center-text">
            <Link to={deviceInfoRoute}>
              {device.computerOsName}
              <div>{`${device.computerOsVersion}  ${device.computerOsArchitecture}`}</div>
            </Link>
          </td>
          <td className="center-text">
            <Link to={deviceInfoRoute}>{device.deviceType}</Link>
          </td>
          <td className="center-text">
            <Link to={deviceInfoRoute}>{device.deviceModel}</Link>
          </td>
          <td className="center-text">
            <Link to={systemResourcesRoute}>{device.installedRam} GB</Link>
          </td>
          <td className="center-text">
            <Link to={systemResourcesRoute}>
              <div className="d-flex flex-column">
                <div>Total: {device.diskTotalSpace}</div>
                <div className="text-success">Free: {device.diskFreeSpace}</div>
              </div>
            </Link>
          </td>
          <td className="center-text">
            <Link to={deviceInfoRoute}>
              {moment(device.lastSeen).format("DD/MM/YYYY HH:mm")}
            </Link>
          </td>
          <td className="center-text">
            <Link to={deviceInfoRoute}>
              {ConvertSecondsToHHMMSS(device.totalDuration).slice(0, -3)} H
            </Link>
          </td>
          <td className="center-text">
            <Link to={applicationsRoute}>
              <span className="text-danger">{device.totalFailureCount}</span>
            </Link>
          </td>
        </tr>
      );
    });

  const { excelData, rowsData } = data.reduce(
    (acc, device) => {
      const rowsObject = {
        computerId: device.computerId,
        computerName: device.computerName,
        computerOsName: device.computerOsName,
        computerOsVersion: device.computerOsVersion,
        computerOsArchitecture: device.computerOsArchitecture,
        deviceType: device.deviceType,
        deviceModel: device.deviceModel,
        installedRam: device.installedRam,
        diskTotalSpace: device.diskTotalSpace,
        diskFreeSpace: device.diskFreeSpace,
        lastSeen: device.lastSeen,
        totalDuration: device.totalDuration,
        totalFailureCount: device.totalFailureCount,
      };

      const exelObject = {
        computerName: device.computerName,
        computerOsName: `${device.computerOsName} ${device.computerOsVersion} ${device.computerOsArchitecture}`,
        deviceType: device.deviceType,
        deviceModel: device.deviceModel,
        installedRam: `${device.installedRam} GB`,
        diskTotalSpace: `Total: ${device.diskTotalSpace} Free: ${device.diskFreeSpace}`,
        lastSeen: moment(device.lastSeen).format("DD/MM/YYYY HH:mm"),
        totalDuration: `${roundNumber(device.totalDuration / 60 / 60, 2)} H`,
        totalFailureCount: device.totalFailureCount,
      };

      acc.rowsData.push(rowsObject);
      acc.excelData.push(exelObject);
      return acc;
    },
    { rowsData: [] as any[], excelData: [] as any[] }
  );

  const rowStrings = {
    key: "computerName",
    items: data.map((device) => [device.computerName]),
  };

  return (
    <div>
      <Table
        pageTitle={"Devices"}
        // intialPage={intialPage}
        rows={rows}
        rowsData={rowsData}
        excelData={excelData}
        columns={columns}
        rowsStrings={rowStrings}
      />
    </div>
  );
}
