import DeviceScore from './deviceScoreComp/DeviceScore'
import Guage from './Guage/Guage'
import styles from './DeviceHealth.module.css'
export default function DeviceHealth (data: any) {
  console.log("device health ==> ", data.data);
  
  return (
    <>
      <DeviceScore value={data.data.deviceScore} />
      <div className={styles['guagesContainer']}>
        <div>
          <Guage
            value={data.data.cpuActivity}
            title='CPU Usage'
            isBtn={false}
            disappled={false}
          />
        </div>
        <div>
          <Guage
            value={data.data.memoryActivity}
            title='Memory Usage'
            isBtn={false}
            disappled={false}
          />
        </div>
        <div>
          <Guage
            value={0.0}
            title='Disk Activity'
            isBtn={false}
            disappled={true}
          />
        </div>
      </div>
    </>
  )
}
