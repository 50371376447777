import ReactApexChart from "react-apexcharts";
import styles from "./DeviceScore.module.css";
import { Computer } from "@styled-icons/remix-fill/Computer";
import styled from "styled-components";

type DeviceScoreProps = {
  value: number;
};
export default function DeviceScore({ value }: DeviceScoreProps) {
  const ComputerIcon = styled(Computer)`
    color: ${value >= 8.6 ? "#4453c5" : value >= 7.1 ? "#FFB200" : "#F04438"};
    width: 80px;
  `;
  return (
    <div className={styles["deviceScoreContainer"]}>
      <div className={styles["deviceprogressIcon"]}>
        <ReactApexChart
          series={[value * 10]}
          options={{
            colors: [
              value >= 8.6 ? "#4453c5" : value >= 7.1 ? "#FFB200" : "#F04438",
            ],
            chart: {
              height: "200px",
              width: "200px",
              type: "radialBar",
              offsetY: 6,
            },
            plotOptions: {
              radialBar: {
                track: {
                  background: "#F7F7F7",
                },
                hollow: {
                  size: "78%",
                },
                dataLabels: {
                  show: false,
                },
              },
            },
            stroke: {
              lineCap: "round",
            },
          }}
          type="radialBar"
        />
        <div className={styles["deviceIcon"]}>
          <ComputerIcon />
        </div>
      </div>
      <div className="dataContainer">
        <div
          className={`${styles["deviceprogress"]} ${
            value >= 8.6
              ? styles["high"]
              : value >= 7.1
              ? styles["average"]
              : styles["low"]
          }`}
        >
          {value.toFixed(1)}
        </div>
        <div className={styles["deviceTxt"]}> Device Score</div>
        <div
          className={
            value >=8.6
              ? styles["high"]
              : value >= 7.1
              ? styles["average"]
              : styles["low"]
          }
        >
          {value >=8.6 ? "" : value >=7.1 ? "Average" : "Low"}
        </div>
      </div>
    </div>
  );
}
