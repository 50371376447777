import './BasicTableComp.module.css'
type TableProps = {
    columns: {
        header: string,
        index: string,
    }[],
    rows: Record<string, any>[] | [];
}
const BasicTableComp = (props: TableProps) => {
    return (
        <table >
            <thead>
                <tr>
                    {props.columns.map((col) => <th>{col.header}</th>)}
                </tr>
            </thead>
            <tbody>
                {props.rows.map((row) => <tr>
                    {props.columns.map((col) => <td>{row[col.index]}</td>)}
                </tr>)}

            </tbody>
        </table>

    )

}
export default BasicTableComp