import http from "./httpService";

const url = "/Computer/GetAllComputers";

const deviceUrl = "/Computer/GetAllDevicesFromComputerSummaries	";
const deviceInfoUrl = "/Computer/GetDeviceInfo";
const deviceAppsUrl = "/Computer/GetDeviceApps";
http.setAuthorizationToken();

export function getAllComputers() {
  return http.get(url);
}

export function getAllDevices(groupId, computerId) {
  console.log(groupId, computerId);

  if (groupId !== undefined && computerId === undefined) {
    return http.get(`${deviceUrl}?groupId=${groupId}`);
  } else if (groupId === undefined && computerId !== undefined) {
    return http.get(`${deviceUrl}?computerId=${computerId}`);
  } else if (groupId !== undefined && computerId !== undefined) {
    return http.get(`${deviceUrl}?computerId=${computerId}&groupId=${groupId}`);
  } else {
    return http.get(`${deviceUrl}`);
  }
}

export function getDeviceInfo(computerId) {
  return http.get(deviceInfoUrl + `?computerId=${computerId}`);
}

export function getDeviceApps(computerId) {
  return http.get(deviceAppsUrl + `?computerId=${computerId}`);
}
export function getCPUData(computerId, startDate, endDate) {
  return http.get(
    `/dex/DeviceScore?computerId=${computerId}&startDate=${startDate}&endDate=${endDate}`
  );
}
