import AuthedClient from "../../client/AuthedClient";
import { ListingProvider } from "../../components/Listing/ListingContext";
import OrganizationManagementBody from "../../components/OrganizationManagement/OrganizationManagementBody/OrganizationManagementBody";
import { OrganizationManegementProvider } from "../../components/OrganizationManagement/OrganizationManegemnetContext";
import { Page } from "../../components/Page";
import { useAuthedClient } from "../../context/AuthedClientContext";
import { getAllDepartments } from "../../services/OrganizationManagementService";

export const OrganizationManagement = () => {
    const authedClient = useAuthedClient();
    function Body(data: any) {
        return (<OrganizationManagementBody data={data} unAssignedDevices={0} />)
    }

    async function fetchData(
        //@ts-ignore
        authedClient: AuthedClient,
    ) {
        return getAllDepartments()

    }
    return (
        <OrganizationManegementProvider>
            <ListingProvider>
                <Page
                    fetchData={() => fetchData(authedClient)}
                    shouldShowNoData={shouldShowNoData}
                    body={Body}
                    title={{
                        mainTitle: " configuration",
                        subTitle: "Organization Management",
                    }}
                />
            </ListingProvider>
        </OrganizationManegementProvider>
    );
};

function shouldShowNoData(): boolean {
    return false;
}
