import styles from './Button.module.css'; // Assuming you want to use this

type ButtonProps = {
    title: string;
    handleClick: () => void;
};

export default function Button({ title, handleClick }: ButtonProps) {
    return (
        <button className={styles['add-btn']} onClick={handleClick}>
            <i className="icon-plus-circle"></i> {title}
        </button>
    );
}
