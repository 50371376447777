import http from './httpService'
const urlComputer = '/Computer'
const url = '/Department'
export type FormData = {
  id?: number
  name: string
  description: string
  computers: string[]
}
export type EditAddFormData = {
  id?: number
  name: string
  description: string
  computerIds: number[]
}

export interface Department {
  id: number
  name: string
  description: string
  numOfComputers: number
}
export async function getAllDepartments () {
  const { data } = await http.get(`${url}`)
  console.log('departments Dataaaaa==>', data)
  return data
}
export async function getAllDevicesbyDepartmentId (id: number) {
  console.log(id)
  const { data } = await http.get(`${url}/${id}`)
  console.log(data)
  return data
}

export function getAllDevices () {
  const data = [
    { value: 8, label: 'Option 1' },
    { value: 9, label: 'Option 2' },
    { value: 10, label: 'Option 3' },
    { value: 11, label: 'Option 4' }
  ]
  return data
}
interface AddSubmitResponse {
  status: number
  data: any
}
interface EditSubmitResponse {
  status: number
 
}

//HANDEL ADD SUBMIT MODAL
export async function handelAddSubmit (
  formData: EditAddFormData
): Promise<AddSubmitResponse | any> {
  try {
    const { status, data } = await http.post(`${url}`, formData)
    return { status, data }
  } catch (e) {
    return e
  }
}

//HANDEL EDIT SUBMIT MODAL
export async function handelEditSubmit (formData: EditAddFormData) : Promise<EditSubmitResponse | any> {
  try {
    console.log('formData before send ', formData)
    const { status } = await http.put(`${url}/${formData.id}`, formData)
    return status
  } catch (e) {
    return e
  }
}

// GET UNAssigned Computers  generally
export async function getUnassigned () {
  try {
    const { data } = await http.get(`${urlComputer}/unassignedToDepartment`)
    console.log(data)
    return data
  } catch (e) {
    return e
  }
}

// DELETE DEPARTMENT
export async function deleteDepartment (id: number) {
  const { status } = await http.delete(`${url}/${id}`)
  console.log(status)
  return status
}

// UNASSIGNED DEVICE FROM DEPARTMENT
export async function deleteDevice (id: number) {
  console.log(id)
  const { status } = await http.post(
    `${urlComputer}/UnassignFromDepartment?computerId=${id} `
  )
  return status
}

export async function getPDFandExcelData () {
  const { data } = await http.get(`${url}/GetDepartmentsWithDevices`)
  console.log(data)
  return data
}
