import http from "./httpService";

const url = "/AgentReports/GetAgentDevicesAvailability";
http.setAuthorizationToken();

function agentDevicesAvailabilityUrl(fromDate, toDate, groupId, computerId) {
  const API = `${url}?from=${fromDate}&to=${toDate}`;
  if (groupId !== undefined && computerId === undefined)
    return API + `&groupId=${groupId}`;
  else if (groupId === undefined && computerId !== undefined)
    return API + `&computerId=${computerId}`;
  else if (groupId !== undefined && computerId !== undefined)
    return API + `&groupId=${groupId}&computerId=${computerId}`;
  else return API;
}

export function getAgentDevicesAvailability(
  fromDate,
  toDate,
  groupId,
  computerId
) {
  return http.get(
    agentDevicesAvailabilityUrl(fromDate, toDate, groupId, computerId)
  );
}
