import { DateTime } from "luxon";

import http from "../httpService";

const url = "/UrlDashboard/UrlLabelCounts";
http.setAuthorizationToken();

export function getMaliciousActivitiesURLs(
  fromDate: DateTime,
  toDate: DateTime,
  computerId: number | undefined,
  groupId : number | undefined

) {
  
  if (groupId !== undefined && computerId === undefined) {
    return http.get(
        `${url}?fromDate=${fromDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&toDate=${toDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&groupId=${groupId}`
      );
  } else if (groupId === undefined && computerId !== undefined) {
    return http.get(
      `${url}?fromDate=${fromDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&toDate=${toDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&computerId=${computerId}`
    );
  } else if (groupId !== undefined && computerId !== undefined) {
    return http.get(
      `${url}?fromDate=${fromDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&toDate=${toDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&computerId=${computerId}&groupId=${groupId}`
    );
  } else {
    return http.get(
      `${url}?fromDate=${fromDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}&toDate=${toDate.toFormat("yyyy-MM-dd HH:mm:ss.SSS")}`
    );
  }

}
