import { Search } from "@styled-icons/bootstrap/Search";
import styled from "styled-components";
const Searchicon = styled(Search)`
  width: 18px;
  height: 18px;
  background-color: white ;
  color: #8080809c
`;
function SearchIcon(props: { handlePass?: () => void }) {
    return <Searchicon onClick={props.handlePass} />;
}

export default SearchIcon;
