import { useCallback, useEffect, useState } from "react";
import styles from "./Tabs.module.css";
import ReactApexChart from "react-apexcharts";
type tabsDataProps = {
  tabsData: string[];
  chartData: any;
};
export default function Tabs({ tabsData, chartData }: tabsDataProps) {
  const [activeTab, setActiveTab] = useState(0);
  const [barChartData, setBarChartData] = useState<any>({
    appName: [],
    data: [],
  });
  
  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  const getMaxData = useCallback(
    (att: string) => {
      return chartData
        .slice()
        .sort(
          (a: { [x: string]: number }, b: { [x: string]: number }) =>
            b[att] - a[att]
        )
        .slice(0, 10);
    },
    [chartData]
  );
  const turnObjIntoArr = useCallback((data: {}[], att: string) => {
    let obj: { appName: string[]; data: number[] } = { appName: [], data: [] };
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
      obj.appName.push(item.processName);
      obj.data.push(item[att]);
    });
    return obj;
  }, []);

  const maxData = useCallback(() => {
    let data: { [x: string]: number | string }[] = [];
    let editedData: { appName: string[]; data: number[] } = {
      appName: [],
      data: [],
    };
    switch (activeTab) {
      case 0:
        data = getMaxData("maxCPU");
        editedData = turnObjIntoArr(data, "maxCPU");

        break;
      case 1:
        data = getMaxData("maxMemoryInMb");
        editedData = turnObjIntoArr(data, "maxMemoryInMb");
        break;
      case 2:
        data = getMaxData("maxDiskInMb");
        editedData = turnObjIntoArr(data, "maxDiskInMb");
        break;
    }

    setBarChartData(editedData);
  }, [activeTab, getMaxData, turnObjIntoArr, setBarChartData]);
  useEffect(() => {
    maxData();
  }, [activeTab, maxData]);

  return (
    <div>
      {/* TABS HEADER .... */}
      <div
        className={`${styles.tabsHeader}  d-flex justify-content-between  m-auto mt-4 `}
      >
        {tabsData.map((tab, index) => (
          <div
            onClick={() => handleTabChange(index)}
            className={
              activeTab === index
                ? `${styles.haederItem} ${styles.active}`
                : styles.haederItem
            }
            key={index}
          >
            {tab}
          </div>
        ))}
      </div>
      {/* TABS CONTENT .... */}
      <div className="tabContent">
        <ReactApexChart
          grid={{
            show: true, // Show or hide grid lines
            borderColor: "#FFFFFF", // Change line color
            strokeDashArray: 5, // Make lines dashed (set to 0 for solid lines)
            xaxis: {
              lines: {
                show: false, // Hide vertical grid lines
              },
            },
            yaxis: {
              lines: {
                show: false, // Show horizontal grid lines
              },
            },
          }}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
              type: "bar",
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: "17px",
              },
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: barChartData.appName, // X-axis categories
              axisTicks: {
                show: false,
              },
              labels: {
                style: {
                  fontSize: "15px",
                  colors: "#333",
                },
              },
            },
            yaxis: {
              tickAmount: 6,
              axisTicks: {
                show: false,
              },
              labels: {
                formatter: (value: any) => {
                  return value;
                },
              },
            },
            tooltip: {
              y: {
                formatter: (value: any) => {
                  return value;
                },
              },
            },
            colors: ["#4049b5"],
            fill: {
              opacity: 0.3, // Make bars slightly transparent
            },
            stroke: {
              show: true,
              width: 2, // Add a border to bars
              colors: ["#4049b5"], // Use the same color as bars for borders
            },
          }}
          series={[
            {
              name: tabsData[activeTab],
              data: barChartData.data,
            },
          ]}
          type="bar"
          height={380}
        />
      </div>
    </div>
  );
}
