import ReactApexChart from 'react-apexcharts'
import styles from './Guage.module.css'
import { ArrowUpRightCircleFill } from '@styled-icons/bootstrap/ArrowUpRightCircleFill'
import styled from 'styled-components'
type GuageProps = {
  value: number
  title: string
  isBtn: boolean
  disappled: boolean
}

export default function Guage ({ value, title, isBtn, disappled }: GuageProps) {
  const ArrowUpRightCircleFillIcon = styled(ArrowUpRightCircleFill)`
    color: #4453c5;
    width: 30px;
    heigh: 30px;
  `
  return (
    <div
      className={`${styles[`guageContainer`]} ${
        disappled ? styles[`disappled`] : styles[`active`]
      }`}
    >
      <ReactApexChart
        series={[value * 10]}
        options={{
          colors: ['#4453C5'],
          chart: {
            type: 'radialBar'
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: '#C5CAED'
              },
              hollow: {
                size: '75%'
              },
              dataLabels: {
                name: {
                  show: true,
                  fontSize: '18px',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  color: '#9C9C9C',
                  offsetY: 30
                },
                value: {
                  show: true,
                  fontSize: '30px',
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: '#4453C5',
                  offsetY: -30,
                  formatter: function () {
                    return value.toFixed(1) + ''
                  }
                }
              }
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: [title]
        }}
        type='radialBar'
      />

      {isBtn && (
          <button className={styles[`btn-page`]}>
            <ArrowUpRightCircleFillIcon />
          </button>
      )}
    </div>
  )
}
