import { useListing } from '../ListingContext'
import styles from './ListingTable.module.css'


export default function ListingTable() {
    const { columns, rows  } = useListing();
    
    
    return (
        <table className={styles['table']}>
            <thead>
                {columns?.map((col, i) => <th key={i}>{col.HeaderName}</th>)}
            </thead>
            <tbody id='test'>
               {rows}
            </tbody>
        </table>
    )
}
