import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import * as XLSX from 'xlsx'
import './Listing.css'
import {
  deleteDevice,
  getAllDevicesbyDepartmentId,
  getPDFandExcelData
} from '../../services/OrganizationManagementService'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ToastFunction, ToastSuccessFunction } from '../../utils/ToastFunction'
import { useOrganizationManegement } from '../OrganizationManagement/OrganizationManegemnetContext'

type Column = { indexName: string; HeaderName: string }
type Row = any
type ListedData = {
  rowId: number
  data: {
    [key: string]: any
  }
}
interface ListingContextProps {
  filterDepartment: string
  setFilterDepartment: (val: string) => void
  columns: Column[] | undefined
  setColumns: (columns: Column[]) => void
  rows: Row[] | undefined
  setRows: (rows: Row[]) => void
  state: { index: number; id: number } | undefined
  showMoreHandler: (index: number, id: number) => void
  listedData: ListedData | undefined
  setListedData: (data: ListedData | undefined) => void
  deleteHandler: any
  PDFHandler: () => void
  downloadExcel: () => any
  downloaded: boolean
  setDownloaded: Dispatch<SetStateAction<boolean>>
  query: string
  setQuery: Dispatch<SetStateAction<string>>
}

// Create the context
const ListingContext = createContext<ListingContextProps | undefined>(undefined)

// Custom hook to use the context
export const useListing = () => {
  const context = useContext(ListingContext)
  if (!context) {
    throw new Error('useListing must be used within a useListingProvider')
  }
  return context
}
// Context provider component
export const ListingProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [columns, setColumns] = useState<undefined | Column[]>(undefined)
  const [rows, setRows] = useState<undefined | Row[]>(undefined)
  const [filterDepartment, setFilterDepartment] = useState('')
  const [state, setState] = useState<{ id: number; index: number } | undefined>(
    undefined
  )
  const [listedData, setListedData] = useState<ListedData | undefined>(
    undefined
  )
  const [clickedItemId, setClickedItemId] = useState<string | null>(null)
  const [downloaded, setDownloaded] = useState<boolean>(false)
  const [query, setQuery] = useState('')
  const { setDepartmentData } = useOrganizationManegement()
  const listHeader = useMemo(() => {
    if (!listedData || !listedData.data || !listedData.data.computers) {
      return []
    }

    return listedData.data.computers.length > 0
      ? Object.keys(listedData.data.computers[0])
      : []
  }, [listedData])

  console.log('listHeader', listHeader)
  useEffect(() => {
    // Reset the download state after the download starts
    if (downloaded) {
      const timer = setTimeout(() => setDownloaded(false), 100) // A short delay to ensure the component renders
      return () => clearTimeout(timer) // Cleanup the timer
    }
  }, [downloaded])

  // show listing function
  const showMoreHandler = useMemo(
    () => (index: number, id: number) => {
     if (state === undefined || state.id !== id) {
        setState({ id: id, index: index })
      } else {
        setState(undefined)
      }
    },
    [state]
  )

  // DELETE ELEMENT FROM LIST
  const deleteHandler = useCallback(
    async (id: number) => {
      const status = await deleteDevice(id)

      if (status === 200) {
        ToastSuccessFunction('Device has been deleted successfully....')

        if (state !== undefined) {
          const listedData = await getAllDevicesbyDepartmentId(state.id)
          setListedData({
            rowId: state.id,
            data: listedData
          })

          // UPDATE department data
          setDepartmentData(prevDepartments => {
            // Create a shallow copy of the departments array
            const updatedDepartments = [...prevDepartments]

            // Find the index of the department to edit
            const departmentIndex = updatedDepartments.findIndex(
              dep => dep.id === state.id
            )

            if (departmentIndex !== -1) {
              // Update the department directly in the copied array
              updatedDepartments[departmentIndex] = {
                ...updatedDepartments[departmentIndex],
                numOfComputers:
                  updatedDepartments[departmentIndex].numOfComputers - 1
              }
            }

            return updatedDepartments
          })
        }
      } else {
        ToastFunction('Something Went Wrong ...')
      }
    },
    [state, setListedData, setDepartmentData]
  )

  // PDF FUN
  async function PDFHandler () {
    const departments = await getPDFandExcelData()
    console.log('pdf data ===> ,', departments)
    const doc = new jsPDF()
    // Loop through departments to generate rows
    let data: any = []
    departments.forEach((dept: any) => {
      console.log('dept==>', dept)

      // Add department header row
      data.push([
        {
          content: dept.name,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
            fillColor: '#eceef9',
            fontSize: 10
          }
        },
        {
          content: dept.description,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
            fillColor: '#eceef9',
            fontSize: 10
          }
        },
        {
          content: dept.computers.length,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
            fillColor: '#eceef9',
            fontSize: 10
          }
        }
      ])

      // Add device details for the department
      dept.computers.forEach((device: any) => {
        data.push([`DeviceName: ${device.name}`])
      })

      // Add a blank row for spacing
      data.push([{ content: '' }])
    })

    // Add title
    doc.text('Department Details', 14, 10)

    // Generate the table
    autoTable(doc, {
      head: [['Department Name', 'Description', 'Number of devices']],
      body: data,
      startY: 20,
      theme: 'grid',
      styles: { cellPadding: 1, fontSize: 8 },
      headStyles: { fillColor: [68, 83, 197] },
      bodyStyles: { fillColor: [247, 247, 247] },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 60 },
        2: { cellWidth: 60 }
      }
    })

    // Save the PDF
    doc.save('Organization-departmnets.pdf')
  }

  useEffect(() => {
    const theMainNode = document.getElementById('test')
    const NodeChildren = theMainNode?.children
    console.log('listedData==>', listedData)

    if (state !== undefined && listedData !== undefined) {
      if (NodeChildren) {
        let ClickedItem: HTMLElement | null = null

        // Find clicked item
        for (let i = 0; i < NodeChildren.length; i++) {
          const dataId = NodeChildren[i]?.getAttribute('data-id')
          if (dataId && Number(dataId) === state?.id) {
            ClickedItem = NodeChildren[i] as HTMLElement
            setClickedItemId(dataId)
            break
          }
        }

        // Remove previously open child rows
        for (let i = 0; i < NodeChildren.length; i++) {
          if (!NodeChildren[i].getAttribute('data-id')) {
            theMainNode?.removeChild(NodeChildren[i])
            i-- // Adjust index after removal
          }
          setListedData(undefined)
        }

        // Create and insert new row
        const newRow = document.createElement('tr')
        let listHtml =
          '<td colspan="6" style="background-color: #F7F7F7; padding: 10px"><ul class="w-100">'

        if (listedData.data.computers.length > 0) {
          listedData.data.computers.forEach(
            (item: { [x: string]: any; id: any }) => {
              let listedHeaderDate = ''
              listHeader.forEach(head => {
                if (!head.toLowerCase().includes('id')) {
                  listedHeaderDate += `<li style="width:${
                    head.toLowerCase().includes('description')
                      ? '500px'
                      : '350px'
                  }">${head}: ${item[head]}</li>`
                }
              })

              listedHeaderDate += `
                  <li>
                    <button style="background-color:transparent; color:red" class="delete-icon" data-id="${item.id}">🗑️</button>
                  </li>
                `
              listHtml += `<li><ul class="d-flex w-100 justify-content-between">${listedHeaderDate}</ul></li>`
            }
          )

          listHtml += '</ul></td>'
        } else {
          listHtml =
            '<td colspan="6" style="background-color: #F7F7F7; padding: 10px">No Computers are assigned yet...</td>'
        }

        newRow.innerHTML = listHtml

        if (ClickedItem?.nextElementSibling) {
          theMainNode?.insertBefore(newRow, ClickedItem.nextElementSibling)
        } else {
          theMainNode?.appendChild(newRow)
        }

        // Add event listeners to delete buttons
        const deleteButtons = newRow.querySelectorAll('.delete-icon')
        deleteButtons.forEach(button => {
          button.addEventListener('click', () => {
            const id = Number(button.getAttribute('data-id'))
            deleteHandler(id)
          })
        })
      }
    } else if (state === undefined && clickedItemId !== null && NodeChildren) {
      Array.from(NodeChildren).forEach(item => {
        if (!item.getAttribute('data-id')) {
          theMainNode?.removeChild(item)
        }
      })
    }
  }, [state, listedData, listHeader, deleteHandler, clickedItemId])

  // EXCEL FUNCTION ...

  const downloadExcel = async () => {
    // GET CSV DATA
    const departments = await getPDFandExcelData()
    let editedDta = departments.flatMap((department: any) => {
      if (department.computers.length === 0) {
        return {
          DepartmentName: department.name,
          Description: department.description,
          DeviceName: 'No Computers Have been assigned Yet'
        }
      }
      return department.computers.map((device: any) => ({
        DepartmentName: department.name,
        Description: department.description,
        DeviceName: device.name
      }))
    })
    console.log(editedDta)

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(editedDta)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Write the workbook and trigger the download
    XLSX.writeFile(workbook, 'organization-department.xlsx')
  }

  return (
    <ListingContext.Provider
      value={{
        setFilterDepartment,
        filterDepartment,
        columns,
        setColumns,
        rows,
        setRows,
        state,
        showMoreHandler,
        listedData,
        setListedData,
        deleteHandler,
        PDFHandler,
        downloadExcel,
        downloaded,
        setDownloaded,
        query,
        setQuery
      }}
    >
      {children}
    </ListingContext.Provider>
  )
}
