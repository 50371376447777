import ListingHeader from '../ListingHeader/ListingHeader'
import styles from './Listing.module.css'
import ListingTable from '../ListingTable/ListingTable'
import { NoData } from '../../NoData'
interface ListingProps {
  length: number
}
export default function Listing ({ length }: ListingProps) {
  return (
    <div className={styles['listing-container']}>
      <ListingHeader />
      {length > 0 ? <ListingTable /> : <NoData />}
    </div>
  )
}
