import { Filters } from "../context/FiltersContext";
import { ToastFunction } from "../utils/ToastFunction";
import { Page } from "../components/Page";
import "../css/AgentAvailabilityStyles.css";
import "../css/ChartContainerStyles.css";
import MyChartBody from "../components/MyCharts/MyChartBody";
import AuthedClient from "../client/AuthedClient";
import { useAuthedClient } from "../context/AuthedClientContext";
import { getApplicationsNameAndNumOfFailures } from "../services/MyDashboard";
import { getMaliciousActivitiesURLs } from "../services/MaliciousActivities/MaliciousActivitiesURLs";

export const MyChart = () => {
  const authedClient = useAuthedClient();

  return (
    <Page
      fetchData={(filters) => fetchData(filters, authedClient)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: "Dashboard",
        subTitle: "Agent Availability",
      }}
    />
  );
};

const fetchData = async (filters: Filters, authedClient: AuthedClient) => {
  const { startDate, endDate, user, group } = filters;

  try {
    const resNonComplaintDevicesAndNonComplintApp =
      await authedClient.getApplicationsAndDevicesCompliance(group, user, startDate, endDate);
    const maliciousActivitiesURLs = await getMaliciousActivitiesURLs(
      startDate,
      endDate,
      user,
      group
    );
    const ApplicationUsage = await authedClient.getApplicationsUsage(
      startDate,
      endDate,
      group,
      user
    );
    const visitedSitesData = await authedClient.getVisitedSites(
      startDate,
      endDate,
      user,
      group
    );
    const resAppFailure = await getApplicationsNameAndNumOfFailures(
      startDate,
      endDate,
      user
    );
    const getProcessesItPerformance =
      await authedClient.getProcessesItPerformance(
        startDate,
        endDate,
        group,
        user
      );
    console.log("getProcessesItPerformance", getProcessesItPerformance);
    const { notCompliedDevicesCount, notAllowedApplicationsCount } =
      resNonComplaintDevicesAndNonComplintApp;

    return {
      notCompliedDevicesCount,
      notAllowedApplicationsCount,
      maliciousActivitiesURLs: maliciousActivitiesURLs.data.data,
      ApplicationUsageData: ApplicationUsage,
      visitedSitesData,
      resAppFailure,
      getProcessesItPerformance,
    };
  } catch (error: any) {
    console.error("Error fetching data:", error); // Log error properly
    ToastFunction("Something went wrong");
  }
};

function shouldShowNoData(data: any): boolean {
  return data === undefined || data.length === 0;
}

function body(data: any) {
  return <MyChartBody data={data} />;
}
