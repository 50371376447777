import styled from "styled-components";
import { ChevronRight } from '@styled-icons/entypo/ChevronRight';

// Styled component for the arrow icon
const RightArrow = styled(ChevronRight) <{ isRotated: boolean | undefined }>`
  color: #36429E;
  width: 15px;
  height: 15px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14.52px;
  cursor: pointer;
  transition: transform 0.5s ease; 
  transform: rotate(${props => (props.isRotated ? '90deg' : '0deg')}); /* Rotate based on state */
`;

function RightArrowIcon(props: { handleOpenModal?: () => void, state: boolean | undefined }) {


    return <RightArrow isRotated={props.state} />; // Pass rotation state to styled component
}

export default RightArrowIcon;
