import axios, { AxiosResponse } from "axios";
import http from "../services/httpService";
import { DateTime } from "luxon";
import {
  AllowedApplicationApi,
  ApplicationLicenseApi,
  ApplicationsComplianceApi,
  AuthenticateApi,
  ComputerApi,
  GraphsApi,
  // GroupApi,
  ProcessesPerformanceApi,
  UserApi,
  WebAppMonitoringApi,
} from "../generated/v1/swagger";
import { basePath } from "./basePath";
import { AppData } from "../components/ApplicationLicense/ApplicationLicenseTable";
import { formatUTCforAPI } from "../utils/Date-Time-Util/DateTimeFormater";
const API_URL: string | undefined = process.env.REACT_APP_BASE_URL;
// License Monitoring
export type GetLicenseMonitoringResponse =
  Array<GetLicenseMonitoringResponseProcess>;

export type GetLicenseMonitoringResponseProcess = {
  applicationId: number;
  applicationName: string;
  applicationVersion: string;
  numberOfDevices: number;
  numberOfActiveLicenses: number;
  numberOfInActiveLicenses: number;
};

export type GetLicenseMonitoringDetails =
  Array<GetLicenseMonitoringResponseDetailsProcess>;

export type GetLicenseMonitoringResponseDetailsProcess = {
  applicationName: string;
  computerId: number;
  computerName: string;
};

// Visited Sites
export type GetVisitedSitesResponse = Array<GetVisitedSitesResponseProcess>;

export type GetVisitedSitesResponseProcess = {
  applicationName: string;
  percentage: number;
  lastVisit: string;
  tabName: string;
};

export type GetProcessesVisitedSitesDetails =
  Array<GetProcessesVisitedSitesDetailsProcess>;

export type GetProcessesVisitedSitesDetailsProcess = {
  computerName: string;
  applicationName: string;
  mainWindowTitle: string;
  groupId: number;
  time: string;
  count: number;
};

// It Performance
export type GetItPerformanceResponse =
  Array<GetProcessesItPerformanceResponseProcess>;

export type GetProcessesItPerformanceResponseProcess = {
  processName: string;
  computersCount: number;
  maxCPU: number;
  minCPU: number;
  averageCPU: number;
  averageDiskInMb: number;
  minDiskInMb: number;
  maxDiskInMb: number;
  averageMemoryPercentage: number;
  averageMemoryInMb: number;
  minMemoryInMb: number;
  maxMemoryInMb: number;
};

//It Performance LineChart
export type GetLineChartData = Array<GetLineChartDataProcess>;
export type GetLineChartDataProcess = {
  timeLabel: "string";
  data: Array<{
    xAxisValue: "string";
    averageCPU: number;
    averageMemoryInMb: number;
    averageMemoryPercentage: number;
    averageDiskInMb: number;
    computersCount: number;
  }>;
};

// Application Usage
export type GetApplicationsUsageResponse = Array<GetApplicationsUsage>;

export type GetApplicationsUsage = {
  durationInSeconds(durationInSeconds: any): import("react").ReactNode;
  processName: string;
  data: [
    {
      processName: String;
      processCount: number;
      computersCount: number;
      durationInSeconds: number;
    }
  ];
};

// Application Usage Summary
export type GetApplicationsUsageSummaryResponse = GetApplicationsUsageSummary;

export type GetApplicationsUsageSummary = {
  applicationsData: Array<{
    processName: String;
    lastMonthProcessCount: number;
    lastMonthComputersCount: number;
    lastMonthDurationInSeconds: number;
    lastQuarterProcessCount: number;
    lastQuarterComputersCount: number;
    lastQuarterDurationInSeconds: number;
    lastHalfProcessCount: number;
    lastHalfComputersCount: number;
    lastHalfDurationInSeconds: number;
    lastYearProcessCount: number;
    lastYearComputersCount: number;
    lastYearDurationInSeconds: number;
  }>;
  lastMonthTotalProcessCount: number;
  lastMonthTotalDurationInSeconds: number;
  lastQuarterTotalProcessCount: number;
  lastQuarterTotalDurationInSeconds: number;
  lastHalfTotalProcessCount: number;
  lastHalfTotalDurationInSeconds: number;
  lastYearTotalProcessCount: number;
  lastYearTotalDurationInSeconds: number;
};

export type GetApplicationsUsagePerUserProcess =
  Array<GetApplicationsUsagePerUser>;

export type GetApplicationsUsagePerUser = {
  data: Array<{
    computerName: string;
    lastMonthDuration: number;
    lastQuarterDuration: number;
    lastHalfDuration: number;
    lastYearDuration: number;
  }>;
  processName: string;
  lastMonthTotalDuration: string;
  lastQuarterTotalDuration: string;
  lastHalfTotalDuration: string;
  lastYearTotalDuration: string;
};

// Applications Performance
export type GetProcessesPerformanceResponse =
  Array<GetProcessesPerformanceResponseProcess>;

export type GetProcessesPerformanceResponseProcess = {
  processName: string;
  totalCount: number;
  successCount: number;
  failureCount: number;
  computersCount: number;
  failureComputersCount: number;
  failPercentage: number;
  totalDurationInSeconds: number;
};

export type GetProcessesPerformanceDetails =
  Array<GetProcessesPerformanceDetailsProcess>;

export type GetProcessesPerformanceDetailsProcess = {
  recordId: number;
  computerId: number;
  computerName: string;
  processId: string;
  userName: string;
  userId: string;
  groupId: null;
  sampleTime: string;
  processName: string;
  isProcessResponding: boolean;
};

export type GetModalDevicesTabDetails = Array<GetModalDevicesTabDetailsProcess>;

export type GetModalDevicesTabDetailsProcess = {
  computerId: number;
  computerName: string;
};

// Groups and Users
export type GetGroups = Array<Group>;

export type Group = {
  // recordId: number;
  // groupName: string;
  // creationDate: string;
  // isDeleted: boolean;
  id: number;
  name: string;
};

export type getUsers = Array<user>;

export type user = {
  computerId: number;
  computerName: string;
  machineDomain: string;
  machineModel: string;
  computerSerial: string;
  computerOsName: string;
  computerOsManufacture: string;
  computerOsVersion: string;
  computerOsArchitecture: string;
  groupId: 2;
};

// Allowed Applications
export type GetAllowedApplications = Array<AllowedApps>;

export type AllowedApps = {
  id: number;
  applicationName: string;
  applicationVersion: string;
  creationDate: string;
  isAllowed: boolean;
  numberOfComputers: number;
};

// Application License
export type AppLicense = {
  details: Array<Object>;
  recordId: number;
  applicationId: number;
  applicationName: string;
  noLicenses: number;
  licenseStartDate: null;
  licenseEndDate: null;
  creationDate: string;
  isDeleted: boolean;
};
export type AppLicenseMonitorType = Array<AppLicense>;

// Applications Compliance
export type GetProcessesNotCompliedApplicationsInstancesDetails =
  Array<GetNotCompliedApplicationsInstancesProcess>;

export type GetNotCompliedApplicationsInstancesProcess = {
  applicationName: string;
  computerName: string;
  groupId: number;
  installationDate: string;
  applicationVersion: string;
  creationDate: string;
};

export type GetApplicationsAndDevicesComplianceResponse = {
  totalApplicationsCount: number;
  allowedApplicationsCount: number;
  notAllowedApplicationsCount: number;
  compliedDevicesCount: number;
  notCompliedDevicesCount: number;
  totalDevicesCount: number;
  applicationsDetails: Array<GetApplicationsAndDevicesComplianceDetail>;
  devicesList: Array<GetApplicationsAndDevicesComplianceResponseDevice>;
};

export type GetApplicationsAndDevicesComplianceResponseDevice = {
  computerId: number;
  computerName: string;
  computerOsArchitecture: string;
  computerOsManufacture: string;
  computerOsName: string;
  computerOsVersion: string;
  computerSerial: string;
  groupId: number;
  machineDomain: string;
  machineModel: string;

  allowedApps: number;
  notAllowedApps: number;
};

export type GetApplicationsAndDevicesComplianceDetail = {
  applicationId: number;
  applicationName: string;
  applicationVersion: string;
  devicesCount: number;
  compliedDevicesCount: number;
  applicationsDetails: Array<GetApplicationsAndDevicesComplianceDetail>;
  isAllowed: boolean;
};

// Web Availability
export type GetWebAvailablity = Array<GetWebAvailablityProcess>;
export type GetWebAvailablityChartDataProcess = {
  data: Array<GetWebAvailablityelementData>;
  status: string;
  statusCode: number;
  message: string;
};
export type GetWebAvailablityProcess = {
  data: Array<GetWebAvailablityData>;
  status: string;
  statusCode: number;
  message: string;
};
export type GetWebAvailablityelementData = {
  webAppID: number;
  applicationName: string;
  url: string;
  totalCount: number;
  successCount: number;
  failureCount: number;
  averageResponseTime: number;
};
export type GetWebAvailablityData = {
  webAppID: number;
  applicationName: string;
  url: string;
  totalCount: number;
  successCount: number;
  failureCount: number;
  averageResponseTime: number;
  percentage: number;
};

// Time Utilization
export type TimeUtil = {
  computerName: string;
  applicationName: string;
  applicationUtilizationStart: string;
  applicationUtilizationEnd: string;
};

// Web Applications
export type webApps = {
  id: number;
  applicationName: string;
  url: string;
  description: string;
};

// User Management
export type GetUsersAdmins = {
  status: string;
  statusCode: number;
  message: string;
  data: Array<GetUsersAdminsData>;
};

export type GetUsersAdminsData = {
  id: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  title: string;
};

// Profile Change Password
export type PassChangeResult = {
  data: boolean;
  status: string;
  statusCode: number;
  message: null;
};

class AuthedClient {
  // private readonly groupApi: GroupApi
  private readonly computerApi: ComputerApi;
  private readonly processesPerformanceApi: ProcessesPerformanceApi;
  private readonly graphsApi: GraphsApi;
  private readonly applicationLicenseApi: ApplicationLicenseApi;
  private readonly applicationsComplianceApi: ApplicationsComplianceApi;
  private readonly webAppMonitoringApi: WebAppMonitoringApi;
  private readonly allowedApplicationApi: AllowedApplicationApi;
  private readonly userApi: UserApi;
  private readonly authenticateApi: AuthenticateApi;

  constructor(token: string) {
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
      (config) => {
        if (config.headers === undefined) {
          config.headers = {};
        }

        if (config.headers.Authorization === undefined) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          //log user Out
          localStorage.clear();
          window.location.href = "/";
        }

        return Promise.reject(error);
      }
    );

    // this.groupApi = new GroupApi(undefined, basePath, axiosInstance)
    this.computerApi = new ComputerApi(undefined, basePath, axiosInstance);
    this.processesPerformanceApi = new ProcessesPerformanceApi(
      undefined,
      basePath,
      axiosInstance
    );
    this.graphsApi = new GraphsApi(undefined, basePath, axiosInstance);
    this.applicationLicenseApi = new ApplicationLicenseApi(
      undefined,
      basePath,
      axiosInstance
    );
    this.applicationsComplianceApi = new ApplicationsComplianceApi(
      undefined,
      basePath,
      axiosInstance
    );
    this.webAppMonitoringApi = new WebAppMonitoringApi(
      undefined,
      basePath,
      axiosInstance
    );
    this.allowedApplicationApi = new AllowedApplicationApi(
      undefined,
      basePath,
      axiosInstance
    );
    this.userApi = new UserApi(undefined, basePath, axiosInstance);
    this.authenticateApi = new AuthenticateApi(
      undefined,
      basePath,
      axiosInstance
    );
  }

  private static dateTimeInQueryParam(date: DateTime) {
    return date.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
  }

  private static dateInQueryParam(date: Date) {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss.SSS");
  }

  // private static groupInQueryParam (group: number) {
  //   return group === 0 ? undefined : group
  // }

  // Users & Groups
  async getGroups(): Promise<GetGroups> {
    // const response = await this.groupApi.apiGroupGetAllGroupsGet();
    const response = await http.get(`${API_URL}/Department`);
    return response.data as unknown as GetGroups;
  }

  async getUsers(param?: number): Promise<getUsers> {
    const response = await this.computerApi.apiComputerGetAllComputersGet(
      param
    );

    return response.data as unknown as getUsers;
  }

  // Applications Performance
  async getProcessesPerformance(
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetProcessesPerformanceResponse> {
    const response =
      await this.processesPerformanceApi.apiProcessesPerformanceGetProcessesPerformanceGet(
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        currentUser
      );

    return response.data as unknown as GetProcessesPerformanceResponse;
  }

  async getFailureDetails(
    ProcessName: string,
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetProcessesPerformanceDetails> {
    const response =
      await this.processesPerformanceApi.apiProcessesPerformanceGetProcessFailureRecordsGet(
        ProcessName,
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        currentUser
      );

    return response.data as unknown as GetProcessesPerformanceDetails;
  }

  async getDevicesDetails(
    ProcessName: string,
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetModalDevicesTabDetails> {
    const response =
      await this.processesPerformanceApi.apiProcessesPerformanceGetProcessDevicesListGet(
        ProcessName,
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        currentUser
      );

    return response.data as unknown as GetModalDevicesTabDetails;
  }

  // It Performance
  async getProcessesItPerformance(
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetItPerformanceResponse> {
    const response =
      await this.processesPerformanceApi.apiProcessesPerformanceGetProcessesCPUMemoryDiskPerformanceSummarizedGet(
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        currentUser
      );

    return response.data as unknown as GetItPerformanceResponse;
  }
  //detailed line chart
  async getDetailedLineChartData(
    fromDate: DateTime,
    toDate: DateTime,
    appId: string | undefined
  ): Promise<GetLineChartData> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;
    if (appId === undefined) {
      response = await axios.get(
        `${API_URL}/ItTrend/GetItTrend?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/ItTrend/GetItTrend?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}&applicationName=${appId}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }

    return response.data;
  }
  // line chart
  async getLineChartData(
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetLineChartData> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;
    if (currentgroup === 0 && currentUser === undefined) {
      response = await axios.get(
        `${API_URL}/ItTrend/GetItTrend?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentUser === undefined && currentgroup !== 0) {
      response = await axios.get(
        `${API_URL}/ItTrend/GetItTrend?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}&departmentId=${currentgroup}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentUser !== undefined && currentgroup === 0) {
      response = await axios.get(
        `${API_URL}/ItTrend/GetItTrend?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/ItTrend/GetItTrend?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(
          toDate
        )}&departmentId=${currentgroup}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }

    return response.data;
  }
  // Visited Sites
  async getVisitedSites(
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ) {
    const response: any =
      await this.graphsApi.apiGraphsGetApdTabsUsageSummaryGet(
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentUser,
        currentgroup,
        
      );
    return response.data;
  }

  async getVisitedSitesDetails(
    siteName: string,
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetProcessesVisitedSitesDetails> {
    const response = await this.graphsApi.apiGraphsGetVisitedSiteDetailsGet(
      siteName,
      AuthedClient.dateTimeInQueryParam(fromDate),
      AuthedClient.dateTimeInQueryParam(toDate),
      currentgroup,
      currentUser
    );

    return response.data as unknown as GetProcessesVisitedSitesDetails;
  }

  // License Monitoring
  async getLicenseMonitoring(
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetLicenseMonitoringResponse> {
    // The backend currently doesn't take a user filter. However, we were instructed to leave keep sending the user
    // filter for now.
    const options =
      currentUser === undefined
        ? undefined
        : { params: { computerId: currentUser } };

    const response: any =
      await this.applicationLicenseApi.apiApplicationLicenseGetApplicationsLicensesUtilizationGet(
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        options
      );

    return response.data.data as unknown as GetLicenseMonitoringResponse;
  }

  async getLicenseMonitoringDetails(
    applicationId: number,
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetLicenseMonitoringDetails> {
    // The backend currently doesn't take a user filter. However, we were instructed to leave keep sending the user
    // filter for now.
    const options =
      currentUser === undefined
        ? undefined
        : { params: { computerId: currentUser } };

    const response =
      await this.applicationLicenseApi.apiApplicationLicenseGetApplicationLicenseDevicesApplicationNameGet(
        applicationId,
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        options
      );

    return response.data as unknown as GetLicenseMonitoringDetails;
  }
  /*
 async getTimeUtilData2(id: number, date: any) {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;
    if (id === 0) {
      response = await axios.get(
        `${API_URL}/TimeUtilization/GetActiveProcessesUsageTimePerDay?day=${date}
      `,

        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/TimeUtilization/GetActiveProcessesUsageTimePerDay/${id}?day=${date}
      `,

        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }
    return response.data.data;
  }
*/
  // Apllications Compliance
  async getNotCompliedApplicationsInstances(
    applicationId: number,
    fromDate: DateTime | undefined,
    toDate: DateTime | undefined,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetProcessesNotCompliedApplicationsInstancesDetails> {
    const user = localStorage.getItem("user");
    const userobj = user !== null ? JSON.parse(user) : null;

    if (!userobj?.token) {
      throw new Error("User is not authenticated.");
    }

    const headers = {
      Authorization: `Bearer ${userobj.token}`,
    };

    let url = `${API_URL}/ApplicationsCompliance/GetNotCompliedApplicationsInstances/${applicationId}`;

    const params: Record<string, string | number> = {};

    if (fromDate && toDate) {
      params.from = formatUTCforAPI(fromDate);
      params.to = formatUTCforAPI(toDate);
    }

    if (currentgroup !== undefined) {
      params.groupId = currentgroup;
    }

    if (currentUser !== undefined) {
      params.computerId = currentUser;
    }

    // Convert all params to strings
    const queryString = new URLSearchParams(
      Object.entries(params).reduce<Record<string, string>>(
        (acc, [key, value]) => {
          acc[key] = String(value); // Convert all values to strings
          return acc;
        },
        {}
      )
    ).toString();

    if (queryString) {
      url += `?${queryString}`;
    }

    try {
      const response = await axios.get(url, { headers });
      return response?.data?.data;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      throw new Error("Failed to fetch data from the server.");
    }
  }

  async getApplicationsAndDevicesCompliance(
    currentUser: number | undefined,
    currentgroup: number | undefined,
    fromDate?: DateTime,
    toDate?: DateTime
  ): Promise<GetApplicationsAndDevicesComplianceResponse> {
    const response =
      await this.applicationsComplianceApi.apiApplicationsComplianceGetApplicationsAndDevicesComplianceGet(
        fromDate === undefined
          ? undefined
          : AuthedClient.dateTimeInQueryParam(fromDate),
        toDate === undefined
          ? undefined
          : AuthedClient.dateTimeInQueryParam(toDate),
        currentUser,
        currentgroup
      );

    let responseData = JSON.parse(JSON.stringify(response.data));
    return responseData.data as GetApplicationsAndDevicesComplianceResponse;

    //return (response.data as unknown) as GetApplicationsAndDevicesComplianceResponse;
  }

  // Web Availability
  async getWebAvailabilityData(
    fromDate: DateTime,
    toDate: DateTime,
    currentgroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetWebAvailablityProcess> {
    const response =
      await this.webAppMonitoringApi.apiWebAppMonitoringGetWebAppsAvailabilityGet(
        AuthedClient.dateTimeInQueryParam(fromDate),
        AuthedClient.dateTimeInQueryParam(toDate),
        currentgroup,
        currentUser
      );

    return response.data as unknown as GetWebAvailablityProcess;
  }

  async getWebAvailabilityChartData(
    webId: number,
    date: any
  ): Promise<Array<GetWebAvailablityChartDataProcess>> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.get(
      `${API_URL}/WebAppMonitoring/GetWebAppsAvailabilityPerDay?webAppId=${webId}&day=${date}`,
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );
    return response.data.data;
  }
  async getWebResponseChartData(
    webId: number,
    date: any
  ): Promise<Array<GetWebAvailablityChartDataProcess>> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.get(
      `${API_URL}/WebAppMonitoring/GetWebAppsResponsePerDay?webAppId=${webId}&day=${date}`,
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );
    return response.data.data;
  }

  // Allowed Applications
  async getAllowedApps(): Promise<Array<AllowedApps>> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.get(
      `${API_URL}/ApplicationsRegistry/GetAllApplicationsRegistry
      `,
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );
    return response.data.data;
  }
  async getAppUsers(id: number): Promise<Array<any>> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.get(
      `${API_URL}/ApplicationsRegistry/GetApplicationUsers/${id}
      `,
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );
    return response.data.data;
  }
  async deleteAllowedApplications(id: number): Promise<boolean> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.delete(
      `${API_URL}/ApplicationsRegistry/DeleteApplication/${id}
      `,
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );
    return response.data.data;
  }

  async addEditAllowedApplications(
    id: number | undefined,
    editing: boolean,
    appName: string,
    appVersion?: string
  ): Promise<boolean> {
    let response: AxiosResponse<unknown>;

    if (editing) {
      response =
        await this.allowedApplicationApi.apiAllowedApplicationUpdateApplicationPut(
          {
            recordId: id!,
            applicationName: appName,
            applicationVersion: appVersion,
          }
        );
    } else {
      response =
        await this.allowedApplicationApi.apiAllowedApplicationInsertApplicationPost(
          {
            applicationName: appName,
            applicationVersion: appVersion === undefined ? null : appVersion,
          }
        );
    }

    return response.data as boolean;
  }

  async allowDisallowApp(id: number): Promise<Array<any>> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.put(
      `${API_URL}/ApplicationsRegistry/Allow-Disallow-Application/${id}

      `,
      {},
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );

    return response.data.data;
  }

  // Application License
  async getApplicationLicense(): Promise<Array<AppData>> {
    const response: any =
      await this.applicationLicenseApi.apiApplicationLicenseGetApplicationsLicensesGet();
    return response.data.data as Array<AppData>;
  }

  async addEditApplicationLicense(
    id: number | undefined,
    editing: boolean,
    noLicenses: number
  ): Promise<boolean> {
    let response: AxiosResponse<unknown>;

    if (editing) {
      response =
        await this.applicationLicenseApi.apiApplicationLicenseUpdateApplicationLicensePut(
          {
            recordId: id!,
            noLicenses,
          }
        );
    } else {
      response =
        await this.applicationLicenseApi.apiApplicationLicenseInsertApplicationLicensePost(
          {
            applicationId: id!,
            noLicenses: noLicenses,
          }
        );
    }

    return response.data as boolean;
  }

  async deleteApplicationLicense(id: number | undefined): Promise<boolean> {
    const response =
      await this.applicationLicenseApi.apiApplicationLicenseDeleteApplicationLicenseIdDelete(
        id!
      );

    return response.data as unknown as boolean;
  }

  async getAllowedAppsWithNoLicense(): Promise<Array<any>> {
    const response =
      await this.applicationLicenseApi.apiApplicationLicenseGetAllowedApplicationsWithoutLicenseGet();

    return response.data as unknown as Array<AllowedApps>;
  }

  //Web Applications
  async getAllWebApps(): Promise<Array<webApps>> {
    const response =
      await this.webAppMonitoringApi.apiWebAppMonitoringGetAllWebAppsGet();

    return (response.data as unknown as { data: Array<webApps> }).data;
  }

  async DeleteWebApp(id: number | undefined): Promise<boolean> {
    const response =
      await this.webAppMonitoringApi.apiWebAppMonitoringDeleteWebApplicationIdDelete(
        id!
      );

    return response.data as unknown as boolean;
  }

  async addEditWebApps(
    id: number | undefined,
    editing: boolean,
    appName: string | null,
    url: string
  ): Promise<boolean> {
    let response: AxiosResponse<unknown>;

    if (editing) {
      response =
        await this.webAppMonitoringApi.apiWebAppMonitoringUpdateWebAppPut({
          id: id!,
          applicationName: appName!,
          url,
          description: "",
        });
    } else {
      response =
        await this.webAppMonitoringApi.apiWebAppMonitoringInsertWebAppPost({
          applicationName: appName!,
          url,
          description: "",
        });
    }

    return response.data as boolean;
  }

  // Time Utilization
  async getTimeUtilizationData(
    id: number,
    date: Date
  ): Promise<Array<TimeUtil>> {
    const response = await this.graphsApi.apiGraphsGetApdAppUsageComputerIdGet(
      id,
      AuthedClient.dateInQueryParam(date)
    );

    return response.data as unknown as Array<TimeUtil>;
  }

  // time utilization2
  async getTimeUtilData2(id: number | undefined, date: any, date2: any) {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;
    if (id === 0 || id === undefined) {
      response = await axios.get(
        `${API_URL}/TimeUtilization/GetActiveProcessesUsageTimePerDay?from=${date}&to=${date2}
      `,

        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/TimeUtilization/GetActiveProcessesUsageTimePerDay/${id}?from=${date}&to=${date2}
      `,

        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }

    return response.data.data;
  }

  // Applications Usage end Points
  async getApplicationsUsage(
    fromDate: DateTime | any,
    toDate: DateTime | any,
    currentGroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetApplicationsUsageResponse> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;

    if (currentUser === undefined && currentGroup === 0) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageForRangeSummary?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentUser !== undefined) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageForRangeSummary?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentGroup !== undefined) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageForRangeSummary?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}&departmentId=${currentGroup}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentUser !== undefined && currentGroup !== undefined) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageForRangeSummary?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(
          toDate
        )}&departmentId=${currentGroup}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageForRangeSummary?from=${formatUTCforAPI(
          fromDate
        )}&to=${formatUTCforAPI(toDate)}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }

    return response.data.data;
  }

  // Applications Usage Summary (usage Report) end Points
  async getApplicationsUsageSummary(
    currentGroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetApplicationsUsageSummaryResponse> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;

    // if (currentUser !== undefined) {
    //   response = await axios.get(
    //     `${API_URL}/ProcessesPerformance/GetApplicationsUsageV2New?computerId=${currentUser}`,
    //     { headers: { Authorization: `Bearer ${userobj.token}` } }
    //   );
    // } else if (currentGroup !== 0) {
    //   response = await axios.get(
    //     `${API_URL}/ProcessesPerformance/GetApplicationsUsageV2New?groupId==${currentGroup}`,
    //     { headers: { Authorization: `Bearer ${userobj.token}` } }
    //   );
    // } else if (currentUser !== undefined && currentGroup !== 0) {
    //   response = await axios.get(
    //     `${API_URL}/ProcessesPerformance/GetApplicationsUsageV2New?groupId=${currentGroup}&computerId=${currentUser}`,
    //     { headers: { Authorization: `Bearer ${userobj.token}` } }
    //   );
    // } else {
    //   response = await axios.get(
    //     `${API_URL}/ProcessesPerformance/GetApplicationsUsageV2New`,
    //     { headers: { Authorization: `Bearer ${userobj.token}` } }
    //   );
    // }

    if (currentUser !== undefined) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageSummary?computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentGroup !== 0) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageSummary?departmentId=${currentGroup}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentUser !== undefined && currentGroup !== 0) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageSummary?departmentId=${currentGroup}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationsUsageSummary`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }

    return response.data.data;
  }

  async getApplicationUsagePerUser(
    applicationName: string,
    currentGroup: number | undefined,
    currentUser: number | undefined
  ): Promise<GetApplicationsUsagePerUser> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    let response = undefined;

    if (currentUser !== undefined) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationUsagePerUser?applicationName=${applicationName}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentGroup !== 0) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationUsagePerUser?applicationName=${applicationName}&departmentId==${currentGroup}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else if (currentUser !== undefined && currentGroup !== 0) {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationUsagePerUser?applicationName=${applicationName}&departmentId=${currentGroup}&computerId=${currentUser}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    } else {
      response = await axios.get(
        `${API_URL}/ProcessesPerformance/GetApplicationUsagePerUser?applicationName=${applicationName}`,
        { headers: { Authorization: `Bearer ${userobj.token}` } }
      );
    }

    return response.data.data;
  }

  // User Management
  async getUsersAdmins(): Promise<GetUsersAdmins> {
    const response = await this.userApi.apiUserGetUsersListGet();

    return response.data as unknown as GetUsersAdmins;
  }

  async updateUser(
    id: string | undefined,
    fullName: string,
    title: string,
    phoneNumber: string,
    email: string
  ): Promise<boolean> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.put(
      `${API_URL}/User/UpdateUser`,
      {
        id,
        fullName,
        title,
        phoneNumber,
        email,
      },
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );

    return response.data as boolean;
  }

  async postUsersAdmins(
    username: string,
    email: string,
    password: string,
    fullName: string,
    phoneNumber: string,
    title: string
  ) {
    return await this.authenticateApi.apiAuthenticateRegisterUserPost({
      username,
      email,
      password,
      fullName,
      phoneNumber,
      title,
    });
  }

  async resetUsersAdminsPassword(id: string, newPassword: string) {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.put(
      `${API_URL}/User/ResetPassword`,
      {
        id,
        newPassword,
      },
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );

    return response.data as boolean;
  }

  async deleteUser(id: string) {
    const user = localStorage.getItem("user");
    const userObj = user !== null && JSON.parse(user);
    const response = await axios.delete(`${API_URL}/User/DeleteUser?id=${id}`, {
      headers: { Authorization: `Bearer ${userObj.token}` },
    });

    return response.data as boolean;
  }

  async getUserSummary(id: string) {
    const user = localStorage.getItem("user");
    const userObj = user !== null && JSON.parse(user);
    const response = await axios.get(
      `${API_URL}/User/GetUserSummary?id=${id}`,
      { headers: { Authorization: `Bearer ${userObj.token}` } }
    );

    return response.data;
  }

  // Profile Component
  async changeUserPassword(
    oldPass: string,
    newPass: string
  ): Promise<PassChangeResult> {
    const user = localStorage.getItem("user");
    const userobj = user !== null && JSON.parse(user);
    const response = await axios.put(
      `${API_URL}/User/ChangePassword
    `,
      {
        oldPassword: oldPass,
        newPassword: newPass,
      },
      { headers: { Authorization: `Bearer ${userobj.token}` } }
    );
    return response.data;
  }

  async getCurrentProfileSummary() {
    const user = localStorage.getItem("user");
    const userObject = user !== null && JSON.parse(user);
    const response = await axios.get(
      `${API_URL}/User/GetCurrentProfileSummary`,
      { headers: { Authorization: `Bearer ${userObject.token}` } }
    );

    return response.data;
  }
}

export default AuthedClient;
