import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import userImage from "../img/user-pic.jpg";
import { sidebarMenu } from "../staticData/SideBarMenu";
import "../css/Nav.css";
import { useSideBarContext } from "../context/SideBarContext";
import { Feedback } from "@styled-icons/material/Feedback";
import FeedBackForm from "../components/FeedBackForm";
export const Nav = ({
  setShowSidebar,
  setShownGroupIndex,
  activeOption,
  setActiveOption,
}: {
  setShowSidebar: any;
  setShownGroupIndex: (index: number) => void;
  activeOption: string;
  setActiveOption: (activeOprion: string) => void;
}) => {
  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [feedBackModalVisibility, setfeedBackModalVisibility] = useState(false);

  const { isClose, setIsClose } = useSideBarContext();
  const user = useAuth().user;
  const auth = useAuth();
  const { name: deviceName } = useParams();
  const dropdownRef = useRef<HTMLUListElement | null>(null); // Refers to a div element
  const RoutesArray: any = [];
  sidebarMenu.map((el) => {
    RoutesArray.push(...el.links);
    return 0;
  });

  const filteredRoutes = RoutesArray.filter((el: any) => {
    return el.RouteName.toLowerCase().includes(searchInput.toLowerCase());
  });

  const handleSearchInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    setShowDropDown(true);
  };

  const searchInputRef = useRef(null);
  // Close dropdown if clicked outside

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Type guard to ensure dropdownRef.current is not null
      const target = event.target as HTMLElement;

      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !target.className?.includes("click-down")
      ) {
        setShow(false); // Close dropdown if click is outside
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const focus = (
    ref: MutableRefObject<null | HTMLInputElement>,
    nextShow: boolean
  ) => {
    if (nextShow) {
      setTimeout(() => ref.current?.focus());
    }
  };

  focus(searchInputRef, showDropDown);

  const renderName = () => {
    let name = "";
    sidebarMenu.forEach((groups) => {

      const pathname = window.location.pathname.split("/")[1];
      if (pathname === "device-view") {
        name = deviceName ? deviceName : activeOption;
        return;
      } else if (pathname.includes("unauthorized")) {
        name = "Unauthorized";
        return;
      } else if (pathname.includes("profile")) {
        name = "Profile";
        return;
      } else {
        groups["links"].forEach((link) => {
          
          if (pathname === link.route.split("/")[1]) name = link.RouteName;
        });
      }
    });


    return name ? name : activeOption;
  };

  return (
    <>
      <div className={`top-nav`}>
        <button
          type="button"
          id="sidebarCollapse"
          className="btn"
          onClick={() => {
            setShowSidebar((state: boolean) => !state);
            setIsClose(!isClose);
          }}
        >
          <i className="icon-menu-1" />
        </button>

        <div className="appNameTitle">
          <h1>{renderName()}</h1>
        </div>
        <ul className="navbar navbar-expand topbar-menu">
          <li className="nav-item dropdown d-block d-md-none">
            <div
              className="nav-link dropdown-toggle mouse-pointer"
              id="navbarDropdown"
              onClick={() => setShowDropDown((state) => !state)}
            >
              <i className="icon-search-7" />
            </div>

            <div
              className="dropdown-menu slideIn"
              aria-labelledby="navbarDropdown"
            >
              <form className="topbar-search d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search…"
                />
                <button type="button" className="btn">
                  <i className="icon-search" />
                </button>
              </form>
            </div>
          </li>
          <li
            className="nav-item dropdown user-control click-down"
            onClick={() => {
              setShow(!show);
              // if (show === false) setShow(true);
              // else setShow(false);
            }}
          >
            <div className="nav-link dropdown-toggle mouse-pointer click-down">
              <i className="user-pic click-down">
                <img src={userImage} alt="" className="click-down" />
                <span className="user-status click-down"></span>
              </i>
              <span className="d-none d-md-inline click-down">
                <span className="userName-text click-down">
                  {auth.user?.username.toUpperCase()}
                </span>
                <small className="click-down">
                  {auth.user?.title?.toUpperCase()}
                </small>
              </span>
            </div>

            <ul
              ref={dropdownRef}
              className={
                show ? "dropdown-menu slideIn show" : "dropdown-menu slideIn"
              }
              aria-labelledby="navbarDropdown4"
            >
              <li>
                <Link
                  to="/profile"
                  className={show ? "dropdown-item show" : "dropdown-item"}
                >
                  <i className="icon-user-2" /> My profile
                </Link>
              </li>
              <li onClick={() => setfeedBackModalVisibility(true)}>
                <button className="dropdown-item">
                  <i style={{ paddingRight: "5px" }}>
                    <Feedback size="16" />
                  </i>
                  Send feedback
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  <i className="icon-logout-2" /> Log out
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <FeedBackForm
        setfeedBackModalVisibility={setfeedBackModalVisibility}
        feedBackModalVisibility={feedBackModalVisibility}
      />
      <div
        style={showDropDown ? { display: "block" } : { display: "none" }}
        className={`${showDropDown
            ? "routes-search-container"
            : "routes-search-container-hide"

          }`}
      >
        <form className="search-input-box topbar-search me-auto d-none d-md-flex">
          <input
            value={searchInput}
            onChange={(e) => handleSearchInputOnChange(e)}
            type="text"
            className="form-control navbar-drop-down-search"
            ref={searchInputRef}
          />
        </form>
        {filteredRoutes.length > 0 ? (
          sidebarMenu.map((group) => {
            return group.links
              .filter((link) => {
                return link.RouteName.toLowerCase().includes(
                  searchInput.toLowerCase()
                );
              })
              .map((route, index) => (
                <Link key={index} to={route.route}>
                  <p
                    style={{ display: `${route.route === "" && "none"}` }}
                    className={`${window.location.pathname === route.route && "active-route"
                      }`}
                    onClick={() => {
                      setShownGroupIndex(route.groupIndex);
                      setSearchInput("");
                      setShowDropDown(false);
                      setActiveOption(route.RouteName);
                    }}
                  >
                    {user?.roles?.includes("SuperAdmin") ? (
                      route.RouteName
                    ) : !user?.roles?.includes("SuperAdmin") &&
                      route.RouteName === "User Management" ? (
                      ""
                    ) : !user?.roles?.includes("SuperAdmin") &&
                      route.RouteName !== "User Management" ? (
                      route.RouteName
                    ) : (
                      <></>
                    )}
                  </p>
                </Link>
              ));
          })
        ) : (
          <p style={{ pointerEvents: "none" }}>No Results</p>
        )}
      </div>
    </>
  );
};
