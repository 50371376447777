import { useMemo, useState, useContext, createContext } from "react";
import { Nav } from "./Nav";
import { SideBar } from "./SideBar";
import { useLocation } from "react-router-dom";
// import routesArray from "../staticData/sidebarMenu.json";
import { sidebarMenu } from "../staticData/SideBarMenu";
const NavbarContext = createContext<any>(false);
export const useNavbarContext = () => useContext(NavbarContext);

export function Layout(props: any) {
  const [activeOption, setActiveOption] = useState("Search Pages");

  const location = useLocation().pathname;
  console.log("location", location);

  // Running it only on first load to be as initial values with `useState`
  const groupAndLinkIndex = useMemo(
    () => getGroupAndLinkIndexes(location),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [showSidebar, setShowSidebar] = useState(false);
  const [shownGroupIndex, setShownGroupIndex] = useState<number | undefined>(
    groupAndLinkIndex.groupIndex
  );

  const onGroupClicked = (index: number) => {
    if (index === shownGroupIndex) {
      setShownGroupIndex(undefined);
    } else {
      setShownGroupIndex(index);
    }
  };

  return (
    <NavbarContext.Provider value={{ showSidebar }}>
      <div className="wrapper">
        <div id="content" className={`${showSidebar && "active"}`}>
          <SideBar
            shownGroupIndex={shownGroupIndex}
            onGroupClicked={onGroupClicked}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            setActiveOption={setActiveOption}
          />
          <Nav
            setShownGroupIndex={setShownGroupIndex}
            setShowSidebar={setShowSidebar}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
          />
          {props.children}
          <div className="footerContainer">
            <p className="copyright2">Copyright © 2022 Nexey.</p>
          </div>
        </div>
      </div>
    </NavbarContext.Provider>
  );
}

function getGroupAndLinkIndexes(location: string): {
  groupIndex: number;
} {
  for (const group of sidebarMenu) {
    for (const route of group.links) {
      if (route.route === location) {
        return { groupIndex: route.groupIndex };
        // return { groupIndex: route.groupIndex, linkIndex: route.id };
      }
    }
  }

  // Should never happen because the router would redirect to landing
  // But to be robust, let's use these as default values. A possible enhancement in the future is to log errors
  return { groupIndex: 0 };
  // return { groupIndex: 0, linkIndex: 0 };
}
