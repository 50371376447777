import { FormEvent } from 'react'
import Select from 'react-select'
import { CloseButton, Modal } from 'react-bootstrap'
import {
  handelAddSubmit,
  handelEditSubmit
} from '../../../services/OrganizationManagementService'
import { useOrganizationManegement } from '../OrganizationManegemnetContext'
import {
  ToastSuccessFunction,
  ToastFunction
} from '../../../utils/ToastFunction'
import { LoaderSpin } from '../../Icons'

export default function OrganizationDepartmentModal() {
  const {
    handelCloseModal,
    showModa,
    formModalData,
    setFormModalData,
    getDevicesByDepartmentIdState,
    unAssignedDevices,
    setDepartmentData
  } = useOrganizationManegement()
  console.log('formModalData==>', formModalData)
  console.log('showModa==>', showModa)
  console.log('unAssignedDevices==>', unAssignedDevices)

  // HANDELChange of Multi select element
  const handleChangeSelect = (selected: any) => {
    setFormModalData(prevState => ({
      ...prevState,
      computers: selected
    }))
  }

  // Handel Change of departments Name / description inputs
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target

    setFormModalData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }
  console.log('formModalData', formModalData)
  const onSublitHandler = async (e: FormEvent) => {
    e.preventDefault();

    if (showModa === 'Add') {
      let res = await handelAddSubmit({
        name: formModalData.name,
        description: formModalData.description,
        computerIds: formModalData.computers.map(({ value }) => value)
      })

      console.log('resresresres', res.message)

      if (res?.status === 201) {
        setDepartmentData(prevDepartments => {
          return [...prevDepartments, res?.data]
        })
        ToastSuccessFunction('Department is Edited Successfully...')
        handelCloseModal()
      } else if (res.message.includes(409))
        ToastFunction('A department with the same name already exists.')
      else ToastFunction('something went wrong ..')
    } else {
      let res = await handelEditSubmit({
        name: formModalData.name,
        id: formModalData.id!,
        description: formModalData.description,
        computerIds: formModalData.computers.map(({ value }) => value)
      });
      console.log('editres', res);
      if (res === 204) {
        setDepartmentData(prevDepartments => {
          const data = [...prevDepartments]
          const editedDepartmentindex = data.findIndex(
            depr => depr.id === formModalData.id
          )

          if (editedDepartmentindex !== -1) {
            data[editedDepartmentindex] = {
              id: formModalData.id!,
              name: formModalData.name,
              description: formModalData.description,
              numOfComputers: formModalData.computers.length
            }
          } else {
            console.warn('Department not found!')
          }

          return data
        })
        ToastSuccessFunction('Department is Edited Successfully...')
        handelCloseModal()
      } else if (res.message.includes(409))
        ToastFunction('A department with the same name already exists.')
      else ToastFunction('something went wrong ..')
    }
  }

  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      onHide={() => { }}
      show={true}
      size='lg'
    >
      <Modal.Header>
        <Modal.Title>{showModa} Department</Modal.Title>
        <CloseButton onClick={handelCloseModal} />
      </Modal.Header>

      <Modal.Body>
        <div className='row g-3'>
          {getDevicesByDepartmentIdState ? (
            // Loader container with height
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ height: '300px' }}
            >
              <LoaderSpin defaultcolor='#000000' iconsize='100px' />
            </div>
          ) : (
            <div className='col-lg-12'>
              <form onSubmit={onSublitHandler}>
                {/* DEPARTMENT NAME FIELD  */}
                <label className='form-label'>
                  Department Name <span className='error-span'>*</span>
                </label>
                <input
                  className={`form-control mb-3 w-100`}
                  name='name'
                  onChange={handleChange}
                  value={formModalData.name}
                  required
                />
                {/* Department Description FIELD  */}
                <label className='form-label'>Department Description</label>
                <textarea
                  className={`form-control`}
                  rows={5}
                  cols={4}
                  name='description'
                  onChange={handleChange}
                  value={formModalData.description}
                />
                <label className='form-label'>Devices</label>
                {/* DEVICE FIELD */}
                <Select
                  options={unAssignedDevices}
                  isMulti
                  value={formModalData.computers}
                  onChange={handleChangeSelect}
                />
                <div className='btns mt-4 d-flex justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-bs-dismiss='modal'
                    onClick={handelCloseModal}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary px-4 mx-2'>
                    {showModa}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
