/* eslint-disable use-isnan */
import { useState } from 'react'
import { CloseButton, Modal } from 'react-bootstrap'
import AuthedClient, {
  GetWebAvailablityChartDataProcess,
  GetWebAvailablityData,
  GetWebAvailablityProcess
} from '../client/AuthedClient'
import { Page } from '../components/Page'
import { Table, width62pxStyle } from '../components/Table'
import { useAuthedClient } from '../context/AuthedClientContext'
import { Filters } from '../context/FiltersContext'
import { roundTo2Places } from '../utils/misc'
import { TabbedContainer } from '../components/TabbedContainer'
import { WebResponseLineChart } from '../components/Charts'
import { WebAvailbLineChart } from '../components/Charts/WebAvailbLineChart'
import { NoData } from '../components/NoData'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import { roundNumber } from '../utils/Mathimatics'
import { DetailsIcon } from '../components/Icons'
import '../css/webAvailabilityStyles.css'
import StateIcon from '../components/Icons/StateIcon'
import { ConvertToUTCAndFormat } from '../utils/Date-Time-Util/DateTimeFormater'
export type GoogleChartTicks = (
  | { v: number | Date; f: string }
  | number
  | Date
)[]
export const WebAvailability = () => {
  const authedClient = useAuthedClient()
  return (
    <Page<GetWebAvailablityProcess>
      fetchData={filters => fetchData(authedClient, filters)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: 'Dashboard',
        subTitle: 'Web Availability'
      }}
    />
  )
}
async function fetchData (
  authedClient: AuthedClient,
  filters: Filters
): Promise<GetWebAvailablityProcess> {
  const { startDate, endDate, group, user } = filters
  return await authedClient.getWebAvailabilityData(
    startDate,
    endDate,
    group,
    user
  )
}
function shouldShowNoData (data: GetWebAvailablityProcess): boolean {
  return data.data.length === 0
}
function body (data: GetWebAvailablityProcess) {
  return (
    <div className='white-box m-0'>
      <div className='row'>
        <WebAvailabilityTable data={data} />
      </div>
    </div>
  )
}
function WebAvailabilityTable (props: { data: GetWebAvailablityProcess }) {
  const { data } = props
  const [showTrendModal, setShowTrendModal] = useState(false)
  const [currentAppName, setCurrentAppName] = useState('')
  const [currentAppId, setCurrentAppId] = useState<number>(0)
  const [date, setDate] = useState(new Date())
  const changeDateListener = (date: any) => {
    setDate(date)
    callwebAvailblityandResponseData(currentAppId, date)
    callwebResponse(currentAppId, date)
  }
  const [webAvailabilityChart, setWebAvailabilityChart] = useState<
    Array<GetWebAvailablityChartDataProcess>
  >([])
  const [webResponseChart, setWebResponseChart] = useState<
    Array<GetWebAvailablityChartDataProcess>
  >([])
  const auth = useAuthedClient()
  async function callwebAvailblityandResponseData (webId: number, Date: any) {
    let dateformatted = ConvertToUTCAndFormat(Date)
    const res: Array<GetWebAvailablityChartDataProcess> =
      await auth.getWebAvailabilityChartData(webId, dateformatted)
    setWebAvailabilityChart(res)
  }
  async function callwebResponse (webId: number, Date: any) {
    let dateformatted = ConvertToUTCAndFormat(Date)
    const res: Array<GetWebAvailablityChartDataProcess> =
      await auth.getWebResponseChartData(webId, dateformatted)
    setWebResponseChart(res)
  }
  const handleCloseTrendModal = () => {
    setShowTrendModal(false)
    setDate(new Date())
  }
  const handleShowTrendModal = async (appName: string, webId: number) => {
    setCurrentAppId(webId)
    await callwebAvailblityandResponseData(webId, date)
    await callwebResponse(webId, date)
    setCurrentAppName(appName)
    setShowTrendModal(true)
  }
  const columns = [
    { name: 'Web Application Name', attr: 'applicationName' },
    {
      name: 'Avg Response Time (sec)',
      attr: 'averageResponseTime',
      style: window.innerWidth > 1400 ? { width: '90px' } : { width: '220px' }
    },
    {
      name: 'Availability Percentage',
      attr: 'percentage',
      style: window.innerWidth > 1400 ? { width: '70px' } : {}
    },
    {
      name: 'Trend',
      style: window.innerWidth > 1400 ? { width: '40px' } : width62pxStyle,
      attr: ''
    }
  ]
  const rows = (array: Array<GetWebAvailablityData>) => {
    return array.map((element, index) => {
      const averageResponseTime = roundNumber(
        element.averageResponseTime / 1000,
        2
      )
      return (
        <tr key={index}>
          <td>{element.applicationName}</td>
          <td>
            <div className='d-flex'>
              <div className='w-4' style={{ width: '40px' }}>
                {averageResponseTime}
              </div>
              <div
                className={`d-flex justify-content-center align-items-center state-info ${
                  element.averageResponseTime >= 200 && averageResponseTime <= 1
                    ? 'medium'
                    : 'low'
                }
                `}
              >
                <div>
                  {element.averageResponseTime >= 200 &&
                    averageResponseTime <= 1 && <StateIcon color='#FFCC55' />}
                  {averageResponseTime > 1 && <StateIcon color='#F04438' />}
                </div>
                <div>
                  {element.averageResponseTime >= 200 &&
                    averageResponseTime <= 1 &&
                    ' Medium'}
                  {averageResponseTime > 1 && ' High'}
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className='d-flex'>
              <div style={{ width: '55px' }}>{element.percentage}%</div>
              <div
                className={`d-flex justify-content-center align-items-center state-info ${
                  element.percentage < 90 ? ' low' : ''
                }`}
              >
                <div>
                  {element.percentage < 90 && <StateIcon color='#F04438' />}
                </div>
                <div>{element.percentage < 90 && ' Low'}</div>
              </div>
            </div>
          </td>
          <td>
            <DetailsIcon
              handleOpenModal={() => {
                handleShowTrendModal(element.applicationName, element.webAppID)
              }}
            />
          </td>
        </tr>
      )
    })
  }
  const rowsData = data.data.map(element => ({
    webAppID: element.webAppID,
    applicationName: element.applicationName,
    averageResponseTime: element.averageResponseTime,
    percentage: roundTo2Places(
      (element.successCount / element.totalCount) * 100
    )
  }))
  const excelData = data.data.map(element => {
    const averageResponseTime = roundNumber(
      element.averageResponseTime / 1000,
      2
    )

    const averageResponseTimeUnit =
      element.averageResponseTime >= 200 && averageResponseTime <= 1
        ? ' Medium'
        : averageResponseTime > 1
        ? ' High'
        : ''
    const percentage = roundTo2Places(
      (element.successCount / element.totalCount) * 100
    )
    const percentageUnit = percentage < 90 ? '% Low' : '%'
    return {
      applicationName: element.applicationName,
      averageResponseTime: averageResponseTime + averageResponseTimeUnit,
      percentage: percentage + percentageUnit
    }
  })
  const rowStrings = {
    key: 'applicationName',
    items: data.data.map(element => [element.applicationName])
  }
  return (
    <div className='col-lg-12'>
      <Table
        pageTitle={'Web Availability'}
        rows={rows}
        rowsData={rowsData}
        excelData={excelData}
        columns={columns}
        rowsStrings={rowStrings}
      />
      {showTrendModal ? (
        <WebAvailabilityTrendModal
          setDate={setDate}
          date={date}
          changeDateListener={changeDateListener}
          showTrendModal={showTrendModal}
          webAvailabilityChart={webAvailabilityChart}
          handleCloseTrendModal={handleCloseTrendModal}
          currentAppName={currentAppName}
          webResponseChart={webResponseChart}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
function WebAvailabilityTrendModal (props: {
  showTrendModal: boolean
  webAvailabilityChart: any
  webResponseChart: any
  handleCloseTrendModal: () => void
  currentAppName: string
  date: any
  changeDateListener: any
  setDate: any
}) {
  const {
    showTrendModal,
    webAvailabilityChart,
    handleCloseTrendModal,
    webResponseChart,
    currentAppName,
    date,
    changeDateListener,
    setDate
  } = props
  const [hasData, setHasData] = useState(true)
  return (
    <Modal
      keyboard={false}
      centered={true}
      scrollable={true}
      show={showTrendModal}
      onHide={handleCloseTrendModal}
      size='lg'
      className='web-availability-modal'
    >
      <Modal.Header>
        <Modal.Title>{currentAppName}</Modal.Title>
        <CloseButton onClick={handleCloseTrendModal} />
      </Modal.Header>
      <div className='webavail'>
        <TabbedContainer
          tabs={[
            {
              title: 'Web Availability',
              body: WebAvailabilityChartModal({
                hasData,
                setHasData,
                currentAppName,
                webAvailabilityChart,
                date,
                changeDateListener,
                setDate
              })
            },
            {
              title: 'Web Response',
              body: WebResponseModal({
                hasData,
                setHasData,
                currentAppName,
                date,
                changeDateListener,
                webResponseChart,
                setDate
              })
            }
          ]}
        />{' '}
      </div>
    </Modal>
  )
}
const lineData: any = {
  TimeLabel: 'Day',
  data: [
    { xValue: '0', yValue: 45 },
    { xValue: '1', yValue: 33 },
    { xValue: '2', yValue: 48 },
    { xValue: '3', yValue: 23 },
    { xValue: '4', yValue: 11 },
    { xValue: '5', yValue: 2 },
    { xValue: '6', yValue: 9 },
    { xValue: '7', yValue: 55 },
    { xValue: '8', yValue: 0 },
    { xValue: '9', yValue: 10 },
    { xValue: '10', yValue: 14 },
    { xValue: '11', yValue: 11 },
    { xValue: '12', yValue: 0 },
    { xValue: '13', yValue: 0 },
    { xValue: '14', yValue: 0 },
    { xValue: '15', yValue: 0 },
    { xValue: '16', yValue: 22 },
    { xValue: '17', yValue: 88 },
    { xValue: '18', yValue: 76 },
    { xValue: '19', yValue: 77 },
    { xValue: '20', yValue: 78 },
    { xValue: '21', yValue: 79 },
    { xValue: '22', yValue: 79 },
    { xValue: '23', yValue: 100 }
  ]
}
function WebResponseModal (props: {
  currentAppName: string
  hasData: boolean
  setHasData: (el: boolean) => void
  setDate: any
  date: any
  changeDateListener: any
  webResponseChart: any
}) {
  const {
    date,
    setDate,
    hasData,
    setHasData,
    changeDateListener,
    currentAppName,
    webResponseChart
  } = props
  const dataChart: any = { TimeLabel: 'Day', data: [] }
  const arrData: any = []
  arrData.push([`time in ${lineData.TimeLabel}`, `response`, 'avrage Value'])
  let avragevalues: any = []
  if (webResponseChart.data !== null) {
    webResponseChart.data.map((el: any, index: number) => {
      let dataObj = {}
      let successSum = 0
      let totalSum = 0
      el.forEach((item: any) => {
        if (item !== null) {
          successSum += item?.avgResponse_MS
          totalSum++
        }
      })
      dataObj = {
        xValue: String(index + 1),
        yValue:
          totalSum > 0
            ? successSum / totalSum
            : webResponseChart.averageResponseForPastXDays
      }
      dataChart.data.push(dataObj)
      avragevalues.push(webResponseChart.averageResponseForPastXDays.toFixed(2))
      return 0
    })
  }
  return (
    <Modal.Body>
      <div className='col-xl-12'>
        <WebAvailabilityDateFilter
          setDate={setDate}
          setHasData={setHasData}
          disabled={false}
          isResponse={true}
          date={date}
          changeDateListener={changeDateListener}
        />
        {hasData && webResponseChart.averageResponseForPastXDays !== 0 ? (
          <WebResponseLineChart
            avragevalues={avragevalues}
            title={currentAppName}
            data={dataChart}
            xAxisTitle='Hours of The Day'
            yAxisTitle='Avg Response Time (sec)'
            yAxisTickAmount={2}
          />
        ) : (
          <NoData />
        )}
      </div>
    </Modal.Body>
  )
}
function WebAvailabilityChartModal ({
  setDate,
  hasData,
  setHasData,
  currentAppName,
  webAvailabilityChart,
  date,
  changeDateListener
}: {
  hasData: boolean
  setHasData: (el: boolean) => void
  setDate: any
  currentAppName: string
  webAvailabilityChart: any
  date: any
  changeDateListener: any
}) {
  const dataChart: any = { TimeLabel: 'Day', data: [] }
  webAvailabilityChart.length > 0 &&
    webAvailabilityChart.map((el: any, index: number) => {
      if (el === null) {
        dataChart.data.push({ xValue: String(index), yValue: 0 })
      } else {
        let dataObj = {}
        let successSum = 0
        let totalSum = 0
        el.map((innerHour: any) => {
          if (innerHour !== null) {
            successSum += innerHour.successCount
            totalSum += innerHour.totalCount
          } 
         
          dataObj = {
            xValue: String(index),
            yValue:totalSum=== 0? "100.00" :  ((successSum / totalSum) * 100).toFixed(2) 
          }
          return 0
        })
        dataChart.data.push(dataObj)
      
        return 0
      }
      return 0
    })
  return (
    <Modal.Body className='webavailModal'>
      <WebAvailabilityDateFilter
        setDate={setDate}
        disabled={false}
        setHasData={setHasData}
        isResponse={false}
        date={date}
        changeDateListener={changeDateListener}
      />
      {hasData ? (
        <WebAvailbLineChart
          max={100}
          data={dataChart}
          title={currentAppName}
          xAxisTitle='Hours of the Day'
          yAxisTitle='Availability %'
          yAxisTickAmount={6}
        />
      ) : (
        <NoData />
      )}
    </Modal.Body>
  )
}
function WebAvailabilityDateFilter (props: {
  disabled: boolean
  isResponse: boolean
  setDate: any
  date: any
  changeDateListener: any
  setHasData: (variable: boolean) => void
}) {
  const {
    //disabled,
    date,
    changeDateListener,
    // setHasData,
    setDate
  } = props
  const [showFromPicker, setShowFromPicker] = useState(false)
  const handleOnChange = (date: any) => {
    setDate(date)
    changeDateListener(date)
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          InputProps={{ readOnly: true }}
          onClick={() => setShowFromPicker(true)}
          onClose={() => setShowFromPicker(false)}
          open={showFromPicker}
          autoOk
          disableToolbar
          variant='inline'
          format='yyyy/MM/dd'
          margin='normal'
          id='date-picker-inline'
          label='Date'
          value={date}
          onChange={handleOnChange}
          // onChange={(date: MaterialUiPickersDate) => setDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
