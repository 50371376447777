import React, { Suspense } from "react";
import "../../../css/timeUtilizationStyles.css";
import TimeUtilLoader from "../TimeUtilizationLoader/TimeUtilLoader";
const AccordionContainer = React.lazy(
  () => import("../TimeUtilizationAccordion/AccordionContainer")
);
const PieChart = React.lazy(() => import("../TimeUtilizationCharts/PieChart"));
function AccordionWithPieChartContainer() {
  return (
    <Suspense fallback={<TimeUtilLoader />}>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="timeUtilizationContentContainer">
            <div id="chart">
              <PieChart />
            </div>
          </div>
        </div>
        <div className="mt-2 col-sm-12 col-md-12 col-lg-12 h-100">
          <Suspense fallback={<TimeUtilLoader />}>
            <div className="h-100 timeUtilizationContentContainer">
              <div className="Accordion-container h-100">
                {/* <ChartTitle mainTitle={"Application per hours"} /> */}
                <AccordionContainer />
              </div>
            </div>
          </Suspense>
        </div>
      </div>
    </Suspense>
  );
}
export default AccordionWithPieChartContainer;
