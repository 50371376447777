import { useAuthedClient } from '../context/AuthedClientContext'
import AuthedClient from '../client/AuthedClient'
import { Page } from '../components/Page'
import { Filters } from '../context/FiltersContext'
import { MaliciousActivitiesTable } from '../components/MaliciousActivities/MaliciousActivitiesTable'
import { StackedChart } from '../components/MaliciousActivities/StackedChart'
import DonutChart from '../components/MaliciousActivities/DonutChart'
import { getMaliciousActivitiesURLs } from '../services/MaliciousActivities/MaliciousActivitiesURLs'
import { getDistinctComputerIdCountByMatchedLabel } from '../services/MaliciousActivities/DistinctComputerIdCountByMatchedLabel'
import { getMatchedLabelCounts } from '../services/MaliciousActivities/MatchedLabelCountsPieChart'
import { matchedLabelCountsByTimePeriod } from '../services/MaliciousActivities/MatchedLabelCountsByTimePeriodBarChart'

export function MaliciousActivities () {
  const authedClient = useAuthedClient()

  return (
    <Page
      fetchData={filters => fetchData(authedClient, filters)}
      shouldShowNoData={shouldShowNoData}
      body={body}
      title={{
        mainTitle: 'Security Monitoring',
        subTitle: 'Malicious Activities'
      }}
    />
  )
}

async function fetchData (
  //@ts-ignore
  authedClient: AuthedClient,
  filters: Filters
): Promise<any> {
  const { startDate, endDate, user, group } = filters;
  console.log(startDate, endDate, user, group,'startDate, endDate, user, group');
  
  const barChartData = await matchedLabelCountsByTimePeriod(
    startDate,
    endDate,
    user,
    group
  )
  const effectedDevices = await getDistinctComputerIdCountByMatchedLabel(
    startDate,
    endDate,
    user,
    group
  );

  const pieChartData = await getMatchedLabelCounts(startDate, endDate, user , group)
  const tableData = await getMaliciousActivitiesURLs(startDate, endDate, user , group)

  return {
    tableData,
    pieChartData,
    effectedDevices,
    barChartData
  }
}

function shouldShowNoData (data: any): boolean {
  return data.length === 0
}

function body (data: any) {
  var effectedDevicesCount = data.effectedDevices.data.data

  return (
    <div>
      <div className='p-3 my-4 rounded border-2 border'>
        <div className='d-flex gap-3'>
          <div className={'h3 text-danger'}>{effectedDevicesCount}</div>
          <div className={'h3 text-gray letter-spacing-1'}>
            Total Affected Devices
          </div>
        </div>
      </div>
      <div className='d-flex gap-4'>
        <DonutChart data={data.pieChartData.data.data} />

        <StackedChart data={data.barChartData.data.data} />
      </div>
      <div className='upperRow'>
        <MaliciousActivitiesTable data={data.tableData.data} />
      </div>
    </div>
  )
}
