import { DateTime } from "luxon";
import http from "../httpService";

const url = "UrlDashboard/GetDistinctComputerIdCountByMatchedLabels";
http.setAuthorizationToken();

export function getDistinctComputerIdCountByMatchedLabel(
  fromDate: DateTime,
  toDate: DateTime,
  computerId: number | undefined,
  groupId: number | undefined
) {
  if (groupId !== undefined && computerId === undefined) {
    return http.get(
      `${url}?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()}&groupId=${groupId}`
    );
  } else if (groupId === undefined && computerId !== undefined) {
    return http.get(
      `${url}?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()}&computerId=${computerId}`
    );
  } else if (groupId !== undefined && computerId !== undefined) {
    return http.get(
      `${url}?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()}&computerId=${computerId}&groupId=${groupId}`
    );
  } else {
    return http.get(
      `${url}?fromDate=${fromDate.toISODate()}&toDate=${toDate.toISODate()}`
    );
  }
}
