import {
  ApplicationsPerformance,
  ApplicationsUsage,
  ApplicationsUsageSummary,
  WebAvailability,
  ApplicationCompilanceTabPage,
  ItPerformancePage,
  VisitedSites,
  LicenseMonitoring,
  AgentAvailability,
  TimeUtilization,
  Profile,
  DevicesList,
  SingleDevice,
  AccessDenied,
  MaliciousActivities,
  MyChart
  
} from "../screens";

import {
  AdminManagement,
  AllowedApplications,
  ApplicationLicense,
  InstalledApplications,
  WebApplications,
  AssignRole,
  DomainManagement,
  OrganizationManagement,
  ConfigureAlerts,
  RemoteActions,
} from "../screens/DataEntry";


const routeConfig = [
  {
    path: "/landing",
    component: MyChart,
    requiredPermissions: ["AdminManagement"],
  },
  {
    path: "/Applications-Performance",
    component: ApplicationsPerformance,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/Applications-Usage",
    component: ApplicationsUsage,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/Applications-Usage-Summary",
    component: ApplicationsUsageSummary,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/Web-Availability",
    component: WebAvailability,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/app-compliance/:applicationsCompliance",
    component: ApplicationCompilanceTabPage,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/it-performance/:itPerformancePage",
    component: ItPerformancePage,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/Visited-Sites",
    component: VisitedSites,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/License-Monitoring",
    component: LicenseMonitoring,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/Agent-Availability",
    component: AgentAvailability,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/devices-list",
    component: DevicesList,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/device-view/:tab/:id/:name",
    component: SingleDevice,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/Malicious-Activities",
    component: MaliciousActivities,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/timeUtilization",
    component: TimeUtilization,
    requiredPermissions: ["ViewEmployeeProductivity"],
  },
  {
    path: "/Application-License",
    component: ApplicationLicense,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/Installed-Applications",
    component: InstalledApplications,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/Allowed-Applications",
    component: AllowedApplications,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/Web-Applications",
    component: WebApplications,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/Domain-Management",
    component: DomainManagement,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/Configure-Alerts",
    component: ConfigureAlerts,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/Remote-Actions",
    component: RemoteActions,
    requiredPermissions: ["Configuration"],
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/user-management",
    component: AdminManagement,
    requiredPermissions: ["AdminManagement"],
  },
  {
    path: "/Assign-Role",
    component: AssignRole,
    requiredPermissions: ["AdminManagement"],
  },
  {
    path: "/organization-management",
    component: OrganizationManagement,
    requiredPermissions: ["ViewMonitoringDashboards"],
  },
  {
    path: "/unauthorized",
    component: AccessDenied,
  },
];

export default routeConfig;
