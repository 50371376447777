import styles from "./MyChart.module.css";
import KPIComp from "./KPIs/KPIComp";
import MaliousImage from "./KPIs/icons/Malious.svg";
import NonComplaintDevice from "./KPIs/icons/error device 1.svg";
import NonComplaintApp from "./KPIs/icons/Icons.svg";
import DonutChart from "./DonutChart/DonutChart";
import { sum } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Tabs from "./Tabs/Tabs";

// import ReactApexChart from 'react-apexcharts'
export default function MyChartBody({ data }: any) {
  // Most Used Application Variables ...
  let totalAvgUsage: any = 0;
  let yAxisData: number[] = [];
  let labels: string[] = [];
  let otherFound = false;
  let otherTotalAvgUsage: any = 0;
  let otherString = "others";

  // Most Visited Sites Variables ...
  const sitesNames: any = [];
  const sitesPercentages: any = [];
  const otherSitesNames: any = [];
  const othersPercentages: any = [];

  const maliciousActivitiesURLsCount: number =
    data.maliciousActivitiesURLs.reduce(
      (accumulator: number, item: { count: number }) =>
        accumulator + item.count,
      0
    );
  console.log("maliciousActivitiesURLsCount", maliciousActivitiesURLsCount);

  // Most Used Application  Fun Handeling
  (function () {
    if (data.ApplicationUsageData.length === 0) {
      return {
        yAxisData: [],
        labels: [],
      };
    }
    for (var i = 0; i < data.ApplicationUsageData.length; i++) {
      totalAvgUsage += data.ApplicationUsageData[i].durationInSeconds;
    }
    data.ApplicationUsageData?.forEach((item: any) => {
      const itemYAxis = (item.durationInSeconds / totalAvgUsage) * 100;
      if (itemYAxis < 5) {
        otherTotalAvgUsage += itemYAxis;
      } else {
        yAxisData.push(+itemYAxis.toFixed(1));
        labels.push(item.processName);
      }
    });
    if (!otherFound) {
      labels.push(otherString);
      otherFound = true;
    }
    yAxisData.push(+otherTotalAvgUsage.toFixed(1));
  })();

  // Most Visited Sites Fun Handeling
  (function () {
    if (data.visitedSitesData.length === 0) {
      return {
        yAxisData: [],
        labels: [],
      };
    }
    data.visitedSitesData.forEach(
      (site: { tabName: string; percentage: number }) => {
        let cleanName = site.tabName.replace("—", "");
        cleanName =
          cleanName.length > 20 ? cleanName.slice(0, 20) + "..." : cleanName;
        if (site.percentage > 5) {
          sitesNames.push(cleanName);
          sitesPercentages.push(+site.percentage.toFixed(1));
        } else {
          otherSitesNames.push(cleanName);
          othersPercentages.push(+site.percentage.toFixed(1));
        }
      }
    );
    if (otherSitesNames.length > 0) {
      sitesNames.push("Others");
    }
    sitesPercentages.push(+sum(othersPercentages).toFixed(1));
  })();

  const [chartData, setChartData] = useState<[string[], number[]]>([[], []]);
  useEffect(() => {
    // Transform data into the desired format
    if (data.resAppFailure !== undefined && data.resAppFailure.length !== 0) {
      const transformedData: [string[], number[]] = data.resAppFailure.reduce(
        (acc: any[][], obj: { applicationName: any; failureCount: any }) => {
          acc[0].push(obj.applicationName); // Push application name
          acc[1].push(obj.failureCount); // Push failure count
          return acc;
        },
        [[], []] as [string[], number[]] // Initial value for reduce
      );
      setChartData(transformedData);
    }
  }, [data]);
  console.log("data in MyChartBody", data);

  return (
    <div>
      {/* 3 KPIS PART  */}
      <div className="kpis-container d-flex justify-content-between mt-4">
        <KPIComp
          imgSVg={MaliousImage}
          count={maliciousActivitiesURLsCount}
          des="Malicious URL"
        />
        <KPIComp
          imgSVg={NonComplaintDevice}
          count={data.notCompliedDevicesCount}
          des="Non Compliant Devices"
        />
        <KPIComp
          imgSVg={NonComplaintApp}
          count={data.notAllowedApplicationsCount}
          des="Non Compliant Applications"
        />
      </div>
      {/* ********************************************************************************* */}
      {/* DonutChart PART  */}
      <div className="mt-4 d-flex justify-content-between">
        <DonutChart
          data={{ yAxisData, labels }}
          title="Most Used Applications"
        />
        <DonutChart
          data={{ yAxisData: sitesPercentages, labels: sitesNames }}
          title="Most Visited Sites"
        />
      </div>
      {/* FAILURE APP VS COUNT  */}
      <div className={styles.barChartContainer}>
        <h3>Top Applications Failures</h3>

        {data.resAppFailure.length !== 0 ? (
          <ReactApexChart
            grid={{
              show: true, // Show or hide grid lines
              borderColor: "#FFFFFF", // Change line color
              strokeDashArray: 5, // Make lines dashed (set to 0 for solid lines)
              xaxis: {
                lines: {
                  show: false, // Hide vertical grid lines
                },
              },
              yaxis: {
                lines: {
                  show: false, // Show horizontal grid lines
                },
              },
            }}
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                type: "bar",
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "17px",
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: chartData[0], // X-axis categories
                axisTicks: {
                  show: false,
                },
                title: {
                  text: "Applications", // X-axis name
                  style: {
                    fontSize: "25px",
                    fontWeight: "bold",
                    color: "#000",
                  },
                  offsetY: 40,
                },
                labels: {
                  style: {
                    fontSize: "15px",
                    colors: "#333",
                  },
                },
              },
              yaxis: {
                tickAmount: 6,
                labels: {
                  formatter: (value: any) => {
                    return value;
                  },
                },
                title: {
                  text: " Failures", // Y-axis name
                  style: {
                    fontSize: "25px",
                    fontWeight: "bold",
                    color: "#333",
                  },
                  offsetX: -50,
                },
              },
              tooltip: {
                y: {
                  formatter: (value: any) => {
                    return value;
                  },
                },
              },
              colors: ["#4049b5"],
              fill: {
                opacity: 0.3, // Make bars slightly transparent
              },
              stroke: {
                show: true,
                width: 2, // Add a border to bars
                colors: ["#4049b5"], // Use the same color as bars for borders
              },
            }}
            series={[
              {
                name: "Number of Failure",
                data: chartData[1],
              },
            ]}
            type="bar"
            height={380}
          />
        ) : (
          <div>
            <p>No Data Available</p>
          </div>
        )}
      </div>

      <div className={styles.maxUsageAPPContainer}>
        <h3>High Utilized Applications</h3>
        {data.getProcessesItPerformance.length !== 0 ? (
          <Tabs
            tabsData={["CPU", "Memory", "Disk"]}
            chartData={data.getProcessesItPerformance}
          />
        ) : (
          <p className="fw-bold text-center">No Data Available</p>
        )}
      </div>
    </div>
  );
}
